import React from "react";
import axiosService from "../utils/axios.service";
import { Link } from "react-router-dom";
import "../scss/dashboard.scss";
import * as convert from "./constant/Convert.helper";

class Dashboard extends React.Component {
  state = {
    isLoaded: false,
    currentStore: localStorage.getItem("currentStore"),
    dataDashboard: {},
    userAdmin: JSON.parse(localStorage.getItem("user_info")),
  };

  componentDidMount() {
    const getDataDashboard = async () => {
      try {
        const response = await axiosService("api/dashboard/" + this.state.currentStore ?? 1, "GET");
        if (response.data.success !== true) return
        return response.data.data;
      } catch (error) {
        return error
      }
    }
    Promise.all([
      getDataDashboard(),
    ]).then((results) => {
      this.setState({
        dataDashboard: results[0],
        isLoaded: true,
      });
    });
  }


  render() {
    if (this.state.isLoaded) {
      return (
        <React.Fragment>
          <div className="admin-page dashboard">
            <div className="admin-body">
              <div className="dashboard-content">
                <div className="wrapper">
                  <div className="dashboard-main-button main-booking">
                    <Link to={{ pathname: "/booking" }}>
                      <div className="icon-bg"></div>
                      <div className="title">Lịch hẹn</div>
                      <i className="far fa-calendar-alt"></i>
                      <div className="report">
                        <div className="value">
                          <span className="count">{this.state.dataDashboard.dataBookings[0]}</span>
                          <div className="note">Sắp tới</div>
                        </div>
                        <div className="value">
                          <span className="count">{this.state.dataDashboard.dataBookings[1]}</span>
                          <div className="note">Tháng này</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {this.state.userAdmin.role != 2 ?
                    <div className="dashboard-main-button main-order">
                      <Link to={{ pathname: this.state.userAdmin.role != 4 ? "/pos" : "/dashboard" }}>
                        <div className="icon-bg"></div>
                        <div className="title">Thu ngân</div>
                        <i className="fas fa-calculator"></i>
                        <div className="report">
                          <div className="value">
                            <span className="count">{this.state.dataDashboard.dataOrders[0]}</span>
                            <div className="note">Hóa đơn hôm nay</div>
                          </div>
                          <div className="value">
                            <span className="count">{convert.convertCurrency(this.state.dataDashboard.dataOrders[1])}</span>
                            <div className="note">Doanh thu hôm nay</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    : null}
                  {this.state.userAdmin.role != 2 ?
                    <div className="dashboard-main-button main-customer">
                      <Link to={{ pathname: "/customer" }}>
                        <div className="icon-bg"></div>
                        <div className="title">Khách hàng</div>
                        <i className="fas fa-female"></i>
                        <div className="report">
                          <div className="value">
                            <span className="count">{this.state.dataDashboard.dataCustomers[0]}</span>
                            <div className="note">Khách mới hôm qua</div>
                          </div>
                          <div className="value">
                            <span className="count">{this.state.dataDashboard.dataCustomers[1]}</span>
                            <div className="note">Khách cũ hôm qua</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    : null}
                </div>
                {this.state.userAdmin.role != 2 ?
                  <div className="wrapper">
                    <div className="dashboard-report-button report-order">
                      <Link to={{ pathname: "/orders" }}>
                        <div className="icon"><i className="fas fa-file-invoice"></i></div>
                        <div className="title">Danh sách hóa đơn</div>
                      </Link>
                    </div>
                    <div className="dashboard-report-button report-daily">
                      <Link to={{ pathname: "/report/daily" }}>
                        <div className="icon"><i className="fas fa-file-invoice-dollar"></i></div>
                        <div className="title">Báo cáo theo ngày</div>
                      </Link>
                    </div>
                    <div className="dashboard-report-button report-commission">
                      <Link to={{ pathname: "/dashboard" }}>
                        <div className="icon"><i className="fas fa-user-friends"></i></div>
                        <div className="title">Hoa hồng nhân viên</div>
                      </Link>
                    </div>
                    { this.state.userAdmin.role == 5 || this.state.userAdmin.role == 6 ?
                    <div className="dashboard-report-button report-inventory">
                      <Link to={{ pathname: "/data/sale-online" }}>
                        <div className="icon"><i className="fas fa-hand-holding-usd"></i></div>
                        <div className="title">Data sale online</div>
                      </Link>
                    </div>
                    : null }
                  </div>
                  : null}
                {this.state.userAdmin.role == 1 ?
                  <div className="wrapper">
                    <div className="dashboard-setting-button setting-general">
                      <Link to={{ pathname: "/dashboard" }}>
                        <div className="title">Thông tin chung</div>
                        <div className="icon"><i className="fas fa-home"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-location">
                      <Link to={{ pathname: "/stores" }}>
                        <div className="title">Quản lý chi nhánh</div>
                        <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-staff">
                      <Link to={{ pathname: "/operators" }}>
                        <div className="title">Nhân viên phục vụ</div>
                        <div className="icon"><i className="fas fa-users"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-financial">
                      <Link to={{ pathname: "/dashboard" }}>
                        <div className="title">Sổ quỹ thu/chi</div>
                        <div className="icon"><i className="fas fa-money-bill"></i></div>
                      </Link>
                    </div>
                    {this.state.userAdmin.role == 1 ?
                      <div className="dashboard-setting-button setting-voucher">
                        <Link to={{ pathname: "/vouchers" }}>
                          <div className="title">Voucher</div>
                          <div className="icon">
                          <i className="fas fa-window-maximize"></i>
                          </div>
                        </Link>
                      </div>
                    : null }

                    {this.state.userAdmin.role == 1 ?
                      <div className="dashboard-setting-button setting-voucher">
                        <Link to={{ pathname: "/strapi" }}>
                          <div className="title">Quản lý Website</div>
                          <div className="icon">
                          <i className="fas fa-window-maximize"></i>
                          </div>
                        </Link>
                      </div>
                    : null }
                  
                    <div className="dashboard-setting-button setting-service">
                      <Link to={{ pathname: "/services" }}>
                        <div className="title">Danh sách dịch vụ</div>
                        <div className="icon"><i className="fas fa-couch"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-product">
                      <Link to={{ pathname: "/product" }}>
                        <div className="title">Danh sách sản phẩm</div>
                        <div className="icon"><i className="fas fa-tags"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-inventory">
                      <Link to={{ pathname: "/emulation" }}>
                        <div className="title">Cent Thi Đua</div>
                        <div className="icon"><i className="fas fa-boxes"></i></div>
                      </Link>
                    </div>
                    <div className="dashboard-setting-button setting-commission">
                      <Link to={{ pathname: "/data/sale-online" }}>
                        <div className="title">Data sale online</div>
                        <div className="icon"><i className="fas fa-hand-holding-usd"></i></div>
                      </Link>
                    </div>
                    
                    <div className="dashboard-setting-button setting-member-cards">
                      {this.state.userAdmin.role == 1 ?
                        <Link to={{ pathname: "/membercards" }}>
                          <div className="title">Thẻ thành viên</div>
                          <div className="icon"><i className="fas fa-address-card"></i></div>
                        </Link>
                      : null}
                    </div>
                    <div className="dashboard-setting-button empty">
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else return <div></div>;
  }
}
export default Dashboard;
