import React from "react";
import axiosService from "../../utils/axios.service";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import Select from "react-select";
import $ from 'jquery';
import Add from "./Add";
import Edit from "./Edit";
import Copy from "./Copy";

import {
  optionsGender, optionsHoursBooking,
  optionsMinuteBooking, customStylesBooking,
  customStylesFilter, optionsStatusBooking,
  optionsSourceBooking, optionsViewOrderRemover,
  optionsStatusBookingForCS
} from '../constant/GeneralData'

// import BookingItems from "./BookingItems";
import BookingItemsSidebar from "./BookingItemsSidebar";
import * as convert from "../constant/Convert.helper"

import "rsuite/dist/rsuite.min.css";
import "./scss/booking.scss";
import Avatar from 'react-avatar';
import { notifications } from "../constant/Notify";
import Delete from "./Delete"
import _ from 'lodash';
import Header from "../Header";
import BookingShowHistory from "./BookingShowHistory";
import Loading from "../customer/Loading";
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_API_URL_WE);

const queryParams = new URLSearchParams(window.location.search)
const type = queryParams.get("type")
const phone = queryParams.get("phone")
const ticketId = queryParams.get("ticketId")
const order_id = queryParams.get("order_id")
class Booking extends React.Component {
  constructor() {
    super();
    this.positionCurrentDay = React.createRef()
    this.state = {
      token: "",
      isLoaded: false,
      showAdd: false,
      showEdit: false,
      overlayLoading: false,
      dataEditBooking: {},
      ranges: [
        {
          label: "30 ngày trước",
          value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
        },
        {
          label: "7 ngày trước",
          value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
        },
        {
          label: "Hôm qua",
          value: [
            startOfDay(addDays(new Date(), -1)),
            endOfDay(addDays(new Date(), -1)),
          ],
        },
        {
          label: "Hôm nay",
          value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
          label: "Ngày mai",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 1))],
        },
        {
          label: "3 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 3))],
        },
        {
          label: "7 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
        },
        {
          label: "30 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 30))],
        },
      ],
      rangesMobile: [
        {
          label: "30 ngày trước",
          value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
        },
        {
          label: "30 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 30))],
        },
        {
          label: "7 ngày trước",
          value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
        },
        {
          label: "7 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
        },
        {
          label: "Hôm qua",
          value: [
            startOfDay(addDays(new Date(), -1)),
            endOfDay(addDays(new Date(), -1)),
          ],
        },
        {
          label: "Hôm nay",
          value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
          label: "Ngày mai",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 1))],
        },
        {
          label: "3 ngày tới",
          value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 3))],
        },
      ],
      filterByStatus: [
        { value: 0, label: "Tất cả trạng thái" },
        { value: 1, label: 'Chưa xác nhận' },
        { value: 2, label: 'Đã xác nhận' },
        { value: 3, label: 'Chờ phục vụ' },
        { value: 4, label: 'Đang phục vụ' },
        { value: 5, label: 'Hủy lịch' },
        { value: 6, label: 'Không đến' },
        { value: 7, label: 'Hoàn thành' },
      ],
      dataStore: [],
      dataService: [],
      dataOperators: [],
      defaultRangeDate: localStorage.getItem("dateRange") ? JSON.parse(localStorage.getItem("dateRange")) : [new Date(), addDays(new Date(), 7)],
      dateRange: [],
      dataBookingList: [],
      currentStore: localStorage.getItem("currentStore") ?? 1,
      quickSetUser: '',
      quickSetDate: '',
      currentDate: [new Date().getFullYear(), ("0" + (new Date().getMonth() + 1)).slice(-2), ("0" + new Date().getDate()).slice(-2)].join("-"),
      nameCreateBy: "",
      nameUpdatedBy: "",
      customerId: null,
      keywordSearchBooking: "",
      dataServiceByCustomer: [{ label: 'Thẻ liệu trình', options: [], }, { label: 'Dịch vụ', options: [] }],
      totalServiceByCustomer: [],
      showDeleteBooking: false,
      dataBookingDelete: {},
      disableButtonAdd: false,
      dataBookingViewMore: [],
      stylePopup: {},
      dataBookingLoadMoreSidebar: [],
      checkMobile: false,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      searchByStatus: 0,
      showPopupExportBooking: false,
      showLoadingFull: false,
      isNewCustomer: false,
      newPhone: "",
      newFullName: "",
      newZaloAccount: "",
      newGender: 1,
      preBooking: false,
      listUser: [],
      serviceNamigo: [],
      showCopy: false,
      dataCopyBooking: null
    }
  }
  disconnect () {
    socket.disconnect();
  }
  scrollToCurrentDay = () => {
    this.positionCurrentDay.current.scrollIntoView({ behavior: "smooth" })
  }
  handleSpin = () => {
    this.setState({
      isLoaded: false
    })
  }
  showPopupAdd = () => {
    this.hideListViewMore();
    this.setState({ showAdd: true, quickSetUser: '', quickSetDate: '' });
  };
  hidePopupAdd = () => {
    this.setState({ showAdd: false, customerId: null });
  };

  showPopupExportBookings = () => {
    this.setState({
      showPopupExportBooking: true,
    })
  }
  hidePopupExportBooking = () => {
    this.setState({
      showPopupExportBooking: false,
    })
  }
  quickCreateBooking = (date, id, name) => {
    this.hideListViewMore();
    this.setState({ showAdd: true, quickSetUser: (id) ? { id: id, name: name } : "", quickSetDate: new Date(date) });
  }

  showEditBooking = async (dataBooking) => {
    this.setState({ overlayLoading: true })
    let dataEditBooking = dataBooking;
    this.hideListViewMore();
    if (dataBooking.created_by) this.getNameUser(dataBooking.created_by, "nameCreateBy", dataBooking.root);
    if (dataBooking.updated_by) {
      this.getNameUser(dataBooking.updated_by, "nameUpdatedBy", dataBooking.source_delete) 
    } else {
      this.setState({
        nameUpdatedBy: ''
      })
    };

    let packageByCustomer = await this.getServiceByCustomer(dataBooking.customers.id);
    let dataService = []
    if (dataEditBooking.root == 3 && dataEditBooking.source_from == 5) {
      dataService = await this.getDataServices();
    }

    let totalServiceByCustomer = [...packageByCustomer, ...dataService];

    let serviceNamigo = this.state.serviceNamigo
    for (const key in serviceNamigo) {
      totalServiceByCustomer = [...totalServiceByCustomer, ...serviceNamigo[key].product]
    }

    let dataServiceByCustomer = [{ label: 'Thẻ liệu trình', options: packageByCustomer, }, { label: 'Dịch vụ', options: dataService }]

    this.setState({
      dataService: dataService,
      packageByCustomer: packageByCustomer,
      showEdit: true,
      dataEditBooking: dataEditBooking,
      dataServiceByCustomer: dataServiceByCustomer,
      totalServiceByCustomer: totalServiceByCustomer,
      overlayLoading: false 
    })
  }

  hideEditBooking = () => {
    this.setState({ showEdit: false });
  };
  getDateRange = (startDate, endDate, steps = 1) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      let year = currentDate.getFullYear(),
        month = ("0" + (currentDate.getMonth() + 1)).slice(-2),
        date = ("0" + currentDate.getDate()).slice(-2),
        day = currentDate.getDay();

      if (currentDate.getMonth() === new Date().getMonth() && currentDate.getDate() === new Date().getDate()) {
        day = 'Hôm nay';
      } else {
        if (day === 0) {
          day = 'C.Nhật';
        } else {
          day = 'Thứ ' + Number(day + 1);
        }
      }
      day = day + ' (' + [date, month].join("/") + ')'
      dateArray.push({
        key: [year, month, date].join("-"),
        label: day
      });
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  }

  convertDateSidebar = (data) => {
    let currentDate = new Date(data),
      year = currentDate.getFullYear(),
      month = ("0" + (currentDate.getMonth() + 1)).slice(-2),
      date = ("0" + currentDate.getDate()).slice(-2),
      day = currentDate.getDay(),
      currentDay = '';

    if (currentDate.getMonth() === new Date().getMonth() && currentDate.getDate() === new Date().getDate()) currentDay = " (Hôm nay)";
    if (day === 0) {
      day = 'C.Nhật';
    } else {
      day = 'Thứ ' + Number(day + 1);
    }

    let dateString = day + ', ' + [date, month, year].join("/") + currentDay;

    return dateString;
  }

  getDataBooking = async (newCache, preBooking, startDate, endDate, storeId, crmToken) => {
    this.setState({ overlayLoading: true })

    let convertStartDate = convert.convertDate(startDate),
        convertEndDate = convert.convertDate(endDate);
    try {
      const res = await axiosService("api/bookings/" + storeId + "?start_date=" + convertStartDate + "&end_date=" + convertEndDate + "&preBooking=" + preBooking + "&newCache=" + newCache, "GET", "", crmToken ? crmToken : this.state.token);
      if (res.data.success == true) this.setState({ overlayLoading: false })
      return res.data.data;
    } catch (error) {
      return console.log(error);
    }
  }

  changeDateRange = async (event) => {
    this.hideListViewMore();
    const dataDateRange = this.getDateRange(event[0], event[1]);
    const dataBooking = await this.getDataBooking(false, this.state.preBooking, event[0], event[1], this.state.currentStore);

    localStorage.setItem("dateRange", JSON.stringify(event));
    this.setState({
      dateRange: dataDateRange,
      dataBookingList: dataBooking,
      defaultRangeDate: [new Date(event[0]), new Date(event[1])]
    });
  }
  reloadData = async (event, booking = null) => {
    const dataBooking = await this.getDataBooking(Math.floor(Math.random() * 10), this.state.preBooking, new Date(this.state.dateRange[0].key), new Date(this.state.dateRange.slice(-1)[0].key), this.state.currentStore);
    this.setState({
      dataBookingList: dataBooking,
    });
    if (event === 'edit') this.setState({ showEdit: false })
    if (event === 'add') {
      this.setState({ showAdd: false, disableButtonAdd: false })
      if ([1, 5, 3].includes(this.state.userAdmin.role)) {
        this.setState({
          dataCopyBooking: booking,
          showCopy: true
        })
      }
    } 
  }

  changeRoot = async (preBooking) => {
    this.setState({ overlayLoading: true, preBooking: preBooking })
    const dataBooking = await this.getDataBooking(false, preBooking, new Date(this.state.dateRange[0].key), new Date(this.state.dateRange.slice(-1)[0].key), this.state.currentStore);
    this.setState({
      dataBookingList: dataBooking,
      overlayLoading: false
    });
  }

  showMoreBooking = (event, data, top, left, max) => {
    this.setState({
      dataBookingViewMore: data,
      stylePopup: {
        top: top === 0 ? 250 : (top * 155) + 250,
        left: left === 0 ? 140 : (left * 140)
      }
    });
  }

  hideListViewMore = (event) => {
    this.setState({ dataBookingViewMore: [] })
  }

  searchBookingByCode = _.debounce(async (event) => {
    // const res = await axiosService("api/bookings/" + this.state.currentStore + "?start_date=" + this.state.dateRange[0].key + "&end_date=" + this.state.dateRange.slice(-1)[0].key + "&code=" + event.target.value, "GET");
    this.setState({
      keywordSearchBooking: event.target.value
    })
  }, 1000)

  searchBookingByStatus = async (event) => {
    this.setState({
      searchByStatus: event.value
    })
  }

  getNameUser = async (event, type, source = null) => {
    let nameUser = '';
    if (source == 3) {
      let dataUser = await axiosService("api/customer/" + event, "GET", "", this.state.token)
      if (dataUser.data) nameUser = dataUser.data.full_name
    } else {
      if (this.state.listUser[event]) nameUser = this.state.listUser[event]['name'] ?? ''
    }
    this.setState({ [type]: nameUser })
  }

  getBookingDetailById = async (id) => {
    let dataBooking = await axiosService("api/bookings/detail/" + id, "GET", "", this.state.token);
    dataBooking = dataBooking.data.data;
    await this.showEditBooking(dataBooking)
  }

  getServiceByCustomer = async (id) => {
    let listPackage = await axiosService("api/package/customer/" + id+"/booking", "GET", "", this.state.token);
    listPackage = listPackage.data.data.listPackage;
    if (listPackage.length > 0) {
      listPackage.map((item, key) => {
        if (item.status === 1) {
          let used = (item.max_used >= 99) ? "Không giới hạn" : (item.max_used - item.count_used) + " lần";
          let label = item.type === "Thẻ lần" ? item.product_name  + " (" + used + ")" : item.product_name;
          listPackage[key] = {
            ...item,
            "label": label,
            "product_name": item.product_name,
            "price": 0,
            "time": item.product.time_using,
            "value": item.package_code ?? item.product_id,
            "product_id": item.product_id,
            "package_code": item.package_code,
            "left_use": item.max_used - item.count_used,
            "range_date_new": item.range_date_new
          }
        }
      })
    }
    return listPackage;
  };

  addBooking = async (data) => {
    if (order_id) data.id_pre_order = order_id
    let res = await axiosService("api/bookings", "POST", data, this.state.token)
    if (res.data.success == true) {
      let newBooking = res.data.data;
      this.reloadData('add', newBooking);
      notifications({ type: 'success', mess: 'Tạo lịch mới thành công' });
    } else {
      notifications({ type: 'error', mess: res.data.message });
      this.setState({
        disableButtonAdd: false
      })
    }
  }

  handleButtonBooking = () => {
    this.setState({
      disableButtonAdd: true
    })
  }

  updateBooking = async (data) => {
    let res = await axiosService("api/bookings/" + data.id, "PUT", data, this.state.token)
    if (res.data.success == true) {
      let newBooking = res.data.data;
      this.reloadData('edit');
      notifications({ type: 'success', mess: 'Sửa lịch thành công' });
    } else {
      notifications({ type: 'error', mess: "Đã có lỗi xảy ra, vui lòng thử lại sau" });
    }
  }

  deleteBooking = async (data) => {
    let res = await axiosService("api/bookings/" + data.id, "DELETE", { user_id: this.state.userAdmin.id }, this.state.token)
    if (res.data.success == true) {
      this.reloadData('edit');
      notifications({ type: 'success', mess: 'Xóa lịch thành công' });
      this.setState({
        showDeleteBooking: false,
      })
    } else {
      notifications({ type: 'error', mess: res.data.message });
    }
  }

  handleShowPopupDelete = async (data) => {
    this.setState({
      showDeleteBooking: true,
      dataBookingDelete: data
    })
  }
  hidePopupDelete = () => {
    this.setState({
      showDeleteBooking: false,
    })
  }

  checkDefaultDateRange = () => {
    let dataBefore = localStorage.getItem("dateRange") ? JSON.parse(localStorage.getItem("dateRange")) : [],
      startDate = new Date(),
      endDate = addDays(new Date(), 7);

    if (dataBefore.length > 0) {
      startDate = new Date(dataBefore[0])
      endDate = new Date(dataBefore[1])
    }
    this.setState({
      defaultRangeDate: [startDate, endDate],
    })
  }

  getClassName = (length) => {
    let col = '';
    if (length > 4 && length < 12) col = "column-2";
    if (length >= 12) col = "column-3";

    return col;
  }

  loadMoreBookingSidebar = (key, data) => {
    let listData = this.state.dataBookingLoadMoreSidebar;
    listData.push(key)
    this.setState({
      dataBookingLoadMoreSidebar: listData
    })
  }
  checkUserInfor = async (email) => {
    try {
      const res = await axiosService(`api/get-infor?email=${email}`)
      return res
    } catch (error) {
      console.error(error)
    }
  }

  handleHideShow(a) {
    $(`#icon-${a}`).toggleClass('active')
    $(`.booking-sidebar-${a}`).toggle();
  }

  resize() {
    let currentHideNav = (window.innerWidth <= 768);
    if (currentHideNav !== this.state.checkMobile) {
      this.setState({ checkMobile: currentHideNav });
    }
  }

  handleExportDataBooking = (data) => {
  this.setState({
    showPopupExportBooking: false,
    showLoadingFull: true
  })

  let param = "?keyword=" + data.keyword + "&store_id=" + data.store_id + "&status=" + data.status + "&source=" + data.source + "&start=" + data.start + "&end=" + data.end
  axiosService("api/export/bookings" + param, "GET", "", this.state.token)
    .then((res) => {
      const response = res.data
      if (response) {
        window.location.href = response
      }
      this.setState({
        showLoadingFull: false
      })
    })
  }

  checkPreBooking = () => {
    setTimeout(() => {
      socket.on("addBooking", async (data) => {
        if (this.state.preBooking) this.changeRoot(this.state.preBooking)
      })
      socket.on("updateBooking", async (data) => {
        if (this.state.preBooking && !this.state.showEdit) this.changeRoot(this.state.preBooking)
      })
    }, 500)
  }

  getDataServices = async () => {
    try {
      let selectDataService = []
      const response = await axiosService("api/product", "GET");
      if (response.data.data.data !== null) {
        for (const key in response.data.data.data) {
          selectDataService.push({
            value: response.data.data.data[key].id,
            label: response.data.data.data[key].product_name,
            time: Number(response.data.data.data[key].meta_object.hour * 60) + Number(response.data.data.data[key].meta_object.minute),
            price: Number(response.data.data.data[key].price),
            category_id: Number(response.data.data.data[key].category_id)
          })
        }

        return selectDataService;
      }
    } catch (error) {
      return console.log(error);
    }
  }

  componentDidMount() {
    const getAllUser = async () => {
      let data = await axiosService("api/users/get-all", "GET")
      let dataUser = []
      if(data.data.success) {
        dataUser = _.keyBy(data.data.data, 'id');
      }
      return dataUser
    }

    const getServiceNamigo = async () => {
      let data = await axiosService("api/namigo/get-service", "GET")
      let newDataService = [];
      if(data.data.success) {
        let listProduct = data.data.data.data
        let listNailsType = data.data.data.dataNailsType
        for (const key in listProduct) {
          let dataProduct = [];
          if (listProduct[key].id != 47) {
            for (const key2 in listProduct[key].product) {
              dataProduct.push({
                value: listProduct[key].product[key2].id,
                label: listProduct[key].product[key2].product_name,
                time: Number(listProduct[key].product[key2].meta_object.hour * 60) + Number(listProduct[key].product[key2].meta_object.minute),
                price: Number(listProduct[key].product[key2].price),
                category_id: Number(listProduct[key].product[key2].category_id)
              })
            }
          }
          if (listProduct[key].id == 47) {
            for (const key3 in listNailsType) {
              if (listNailsType[key3].parent_category_id == 47) {
                dataProduct.push({
                  value: listNailsType[key3].id,
                  label: listNailsType[key3].name,
                  time: 0,
                  price: 0,
                  category_id: 47
                })
              }
            }
          }
          newDataService.push({...listProduct[key], product: dataProduct})
        }
      }
      return newDataService
    }

    if (!this.state.userAdmin) window.location.replace("/");
    const getDataStores = async () => {
      try {
        let selectDataStore = []
        const response = await axiosService("api/stores", "GET");
        if (response.data.data !== null) {
          for (const key in response.data.data) {
            selectDataStore.push({
              value: response.data.data[key].id,
              label: response.data.data[key].name_store
            })
          }
          return selectDataStore;
        }
      } catch (error) {
        return console.log(error);
      }
    }
    const getDataOperators = async () => {
      try {
        let selectDataOperators = []
        const response = await axiosService("api/users/store/" + this.state.currentStore, "GET");
        if (response.data.data !== null) {
          for (const key in response.data.data) {
            selectDataOperators.push({
              value: response.data.data[key].id,
              label: response.data.data[key].name,
              image: response.data.data[key].avatar_url,
            })
          }

          return selectDataOperators;
        }
      } catch (error) {
        console.log(error)
      }
    }
    const autoGetDateRange = () => {
      return this.getDateRange(this.state.defaultRangeDate[0], this.state.defaultRangeDate[1])
    }
    const autoGetDataBooking = async () => {
      let startDate = this.state.defaultRangeDate[0],
        endDate = this.state.defaultRangeDate[1];

      return this.getDataBooking(false, this.state.preBooking, startDate, endDate, this.state.currentStore);
    }
    const autoLoadBooking = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const action = queryParams.get('action');
      const bookingId = queryParams.get('booking_id');
      if (bookingId && action == "view") {
        await this.getBookingDetailById(bookingId);
      }
      const custId = queryParams.get('cust_id');
      if (custId && action == "create") {
        this.setState({ showAdd: true, quickSetUser: '', quickSetDate: '', customerId: custId });
      }

    }
    
    Promise.all([
      autoGetDateRange(),
      autoGetDataBooking(),
      getDataStores(),
      getDataOperators(),
      getAllUser(),
      getServiceNamigo(),
    ]).then((results) => {
      this.setState({
        dateRange: results[0] ?? [],
        dataBookingList: results[1] ?? [],
        dataStore: results[2] ?? [],
        dataOperators: results[3] ?? [],
        listUser: results[4],
        serviceNamigo: results[5],
        isLoaded: true,
      });
      autoLoadBooking()
    });

    this.checkPreBooking()

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.checkDefaultDateRange()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div>
          <Header type={type} token={this.state.token} />
          <div className="admin-page booking">
            <Loading
              show={this.state.showLoadingFull}
            ></Loading>
            <div className="page-title">
              <div className="title-page">
                <i className="fa fa-fas fa-calendar-alt"></i>
                {this.state.preBooking ? " Lịch chờ xác nhận" : " Lịch đã xác nhận"}
                {this.state.userAdmin.role !== 4 ?
                  <div className="button-cent hide-desktop"style={this.state.preBooking ? {opacity: 0} : {}} onClick={this.state.preBooking ? () => {} : this.showPopupAdd}>
                    <span>
                      <i className="fa fa-fas fa-calendar-alt"></i>
                    </span>
                    Đặt lịch
                  </div>
                : null}
              </div>
              <div className="header-right">
                <DateRangePicker
                  className="picker-booking"
                  showOneCalendar={this.state.checkMobile}
                  placement={this.state.checkMobile ? "bottomStart" : "bottomEnd"}
                  size="sm"
                  style={{ height: 32 }}
                  // defaultValue={this.state.defaultRangeDate}
                  value={this.state.defaultRangeDate}
                  format={"dd-MM-yyyy"}
                  ranges={this.state.checkMobile ? this.state.rangesMobile : this.state.ranges}
                  onChange={this.changeDateRange}
                />
                <div className="button-cent mr-3" onClick={() => this.changeRoot(!this.state.preBooking)}>
                  <span>
                    <i className="fa fa-fas fa-calendar-alt"></i>
                  </span>
                  {this.state.preBooking ? "Lịch đã xác nhận" : "Lịch chờ xác nhận"}
                </div>
                <div className="button-cent mr-3" style={this.state.preBooking ? {opacity: 0} : {}} onClick={this.state.preBooking ? () => {} : this.showPopupExportBookings}>
                  <span>
                    <i className="fa fa-fas fa-calendar-alt"></i>
                  </span>
                  Export lịch
                </div>
                {this.state.userAdmin.role !== 4 ?
                  <div className="button-cent hide-mobile"style={this.state.preBooking ? {opacity: 0} : {}} onClick={this.state.preBooking ? () => {} : this.showPopupAdd}>
                    <span>
                      <i className="fa fa-fas fa-calendar-alt"></i>
                    </span>
                    Đặt lịch
                  </div>
                : null}
              </div>
            </div>
            <div className="admin-body">
              <div className="dashboard-content">
                <div className="wrapper">
                  {!this.state.checkMobile ?
                    <div className="fixed-column panel-pre-booking">
                      <div className="table_container">
                        <div className="table-wrapper">
                          <div className="title-block">Chờ xác nhận</div>
                          <div className="body-col">
                              <div className="booking-right-list">
                                <div className="item-list-booking">
                                  <div className="data-list">
                                    {this.state.dataBookingList?.bookingWebsite && this.state.dataBookingList?.bookingWebsite.length > 0 ?
                                      (this.state.dataBookingList?.bookingWebsite).map((data, key) => (
                                        <BookingItemsSidebar key={key}
                                          customStylesBooking={customStylesBooking}
                                          optionsStatusBooking={optionsStatusBooking}
                                          optionsStatusBookingForCS={optionsStatusBookingForCS}
                                          optionsSourceBooking={optionsSourceBooking}
                                          dataBookingList={data}
                                          dataService={this.state.dataService}
                                          showEditBooking={(event) => this.showEditBooking(event)}
                                          reloadData={this.reloadData}
                                          getServiceByCustomer={this.getServiceByCustomer}
                                          keywordSearchBooking={this.state.keywordSearchBooking}
                                          updateBooking={this.updateBooking}
                                          optionsViewOrRemover={optionsViewOrderRemover}
                                          deleteBooking={this.handleShowPopupDelete}
                                          checkMobile={this.state.checkMobile}
                                          handleSpin={this.handleSpin}
                                          searchByStatus={this.state.searchByStatus}
                                          changeDateRange={this.changeDateRange}
                                          defaultRangeDate={this.state.defaultRangeDate}
                                          waitingType={3}
                                        />
                                      ))
                                    : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  :null}
                  {!this.state.checkMobile ?
                    <div className="fixed-column panel-waiting">
                      <div className="table_container">
                        <div className="table-wrapper">
                          <div className="title-block">Chờ làm dịch vụ</div>
                          <div className="body-col">
                            <div className="booking-right-list">
                              <div className="item-list-booking">
                                <div className="data-list">
                                  {this.state.dataBookingList?.bookingWaiting && this.state.dataBookingList?.bookingWaiting.length > 0 ?
                                    (this.state.dataBookingList?.bookingWaiting).map((data, key) => (
                                      <BookingItemsSidebar key={key}
                                        customStylesBooking={customStylesBooking}
                                        optionsStatusBooking={optionsStatusBooking}
                                        optionsStatusBookingForCS={optionsStatusBookingForCS}
                                        optionsSourceBooking={optionsSourceBooking}
                                        dataBookingList={data}
                                        dataService={this.state.dataService}
                                        showEditBooking={(event) => this.showEditBooking(event)}
                                        reloadData={this.reloadData}
                                        getServiceByCustomer={this.getServiceByCustomer}
                                        keywordSearchBooking={this.state.keywordSearchBooking}
                                        updateBooking={this.updateBooking}
                                        optionsViewOrRemover={optionsViewOrderRemover}
                                        deleteBooking={this.handleShowPopupDelete}
                                        checkMobile={this.state.checkMobile}
                                        handleSpin={this.handleSpin}
                                        searchByStatus={this.state.searchByStatus}
                                        defaultRangeDate={this.state.defaultRangeDate}
                                        waitingType={2}
                                      />
                                    ))
                                  : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  : null}
                  <div className="fixed-column panel-order">
                    <div className="header-col">
                      <div className="search-button">
                        <input
                          className="search-input"
                          placeholder="Tìm theo Mã đặt lịch, Họ tên hoặc Số điện thoại khách hàng"
                          onChange={this.searchBookingByCode}
                        />
                      </div>
                      <div className="filter-by-status">
                        <Select
                          styles={customStylesFilter}
                          isSearchable={false}
                          className="select"
                          defaultValue={this.state.filterByStatus[0]}
                          options={this.state.filterByStatus}
                          onChange={this.searchBookingByStatus}
                        />
                      </div>
                      {this.state.dataBookingList.bookings.data[this.state.currentDate] ?
                        <div className="booking-list-today" onClick={this.scrollToCurrentDay}>
                          Hôm nay có <span className="text-danger">{this.state.dataBookingList.bookings.data[this.state.currentDate].total}{ }</span> lịch hẹn. Bấm để xem
                        </div>
                        : ""}
                    </div>
                    <div className="body-col">
                      <div className="booking-right-list" id="booking-right-list">
                        {this.state.dataBookingList.bookings.total > 0 ?
                          Object.entries(this.state.dataBookingList.bookings.data).reverse().map((data, key) => (
                            data[1].total > 0 ?
                              <div className="item-list-booking"
                                ref={(data[0] === this.state.currentDate) ? this.positionCurrentDay : null}
                                key={key} id={(data[0] === this.state.currentDate) ? "current-day" : data[0]}>
                                <div className="title-list" onClick={() => { this.handleHideShow(data[0]) }}>
                                  <div className="date-booking">
                                    <div className={["alpha-grey date-separator", (data[0] === this.state.currentDate) ? "cell-today" : ""].join(" ")}>
                                      <i className="fa fa-fas fa-calendar-alt"></i> {this.convertDateSidebar(data[0])}
                                      <span className="total-booking">
                                        Tổng {data[1].total} lịch / {data[1].totalCancel} huỷ
                                        <i className="fas fa-chevron-circle-down" id={`icon-${data[0]}`}></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className={"data-list booking-sidebar-" + `${data[0]}`}>
                                  {data[1].bookingList.slice(0, (this.state.dataBookingLoadMoreSidebar.includes(key)) || this.state.keywordSearchBooking !== '' || this.state.searchByStatus != 0 ? data[1].total : 10).map((data2, key2) => (
                                    <BookingItemsSidebar key={key2}
                                      customStylesBooking={customStylesBooking}
                                      optionsStatusBooking={optionsStatusBooking}
                                      optionsStatusBookingForCS={optionsStatusBookingForCS}
                                      optionsSourceBooking={optionsSourceBooking}
                                      dataBookingList={data2}
                                      dataService={this.state.dataService}
                                      showEditBooking={(event) => this.showEditBooking(event)}
                                      reloadData={this.reloadData}
                                      getServiceByCustomer={this.getServiceByCustomer}
                                      keywordSearchBooking={this.state.keywordSearchBooking}
                                      updateBooking={this.updateBooking}
                                      optionsViewOrRemover={optionsViewOrderRemover}
                                      deleteBooking={this.handleShowPopupDelete}
                                      checkMobile={this.state.checkMobile}
                                      handleSpin={this.handleSpin}
                                      searchByStatus={this.state.searchByStatus}
                                      defaultRangeDate={this.state.defaultRangeDate}
                                      waitingType={1}
                                    />
                                  ))}
                                </div>

                                {data[1].total > 10 && !this.state.dataBookingLoadMoreSidebar.includes(key) && this.state.keywordSearchBooking == '' && this.state.searchByStatus == 0 ?
                                  <div onClick={() => this.loadMoreBookingSidebar(key, data[1].bookingList.slice(10, data[1].total))} className={"load-more booking-sidebar-" + `${data[0]}`}>Xem thêm</div>
                                  : null}
                              </div>
                              : null
                          ))
                          : <div className="empty-booking">Không có dữ liệu phù hợp</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showAdd ?
              <Add show={this.state.showAdd}
                serviceNamigo={this.state.serviceNamigo}
                isNewCustomer={this.state.isNewCustomer}
                newPhone={this.state.newPhone}
                newFullName={this.state.newFullName}
                newZaloAccount={this.state.newZaloAccount}
                newGender={this.state.newGender}
                close={this.hidePopupAdd}
                optionsGender={optionsGender}
                optionsHour={optionsHoursBooking}
                optionsMinute={optionsMinuteBooking}
                optionsDays={this.state.optionsDays}
                optionsMonths={this.state.optionsMonths}
                dataStore={this.state.dataStore}
                dataService={this.state.dataService}
                dataOperators={this.state.dataOperators}
                quickSetUser={this.state.quickSetUser}
                quickSetDate={this.state.quickSetDate}
                customerId={this.state.customerId}
                getServiceByCustomer={this.getServiceByCustomer}
                addBooking={this.addBooking}
                handleDisableButtonAdd={this.handleButtonBooking}
                disableButtonAdd={this.state.disableButtonAdd}
                typeHandleInfoCustomer= {true}
              />
            : null}

            {this.state.showPopupExportBooking ?
              <BookingShowHistory show={this.state.showPopupExportBooking}
                close={this.hidePopupExportBooking}
                filterByStatus={this.state.filterByStatus}
                handleExportDataBooking={this.handleExportDataBooking}
              />
            : null}

            {this.state.showEdit ?
              <Edit 
                show={this.state.showEdit}
                serviceNamigo={this.state.serviceNamigo}
                close={this.hideEditBooking}
                optionsGender={optionsGender}
                optionsHour={optionsHoursBooking}
                optionsMinute={optionsMinuteBooking}
                optionsDays={this.state.optionsDays}
                optionsMonths={this.state.optionsMonths}
                dataStore={this.state.dataStore}
                packageByCustomer={this.state.packageByCustomer}
                totalServiceByCustomer={this.state.totalServiceByCustomer}
                dataServiceByCustomer={this.state.dataServiceByCustomer}
                dataOperators={this.state.dataOperators}
                dataEditBooking={this.state.dataEditBooking}
                optionsStatusBooking={optionsStatusBooking}
                optionsStatusBookingForCS={optionsStatusBookingForCS}
                optionsSourceBooking={optionsSourceBooking}
                nameUpdatedBy={this.state.nameUpdatedBy}
                nameCreateBy={this.state.nameCreateBy}
                updateBooking={this.updateBooking}
                deleteBooking={this.deleteBooking}
                dataUser={this.state.userAdmin.role}
                getServiceByCustomer={this.getServiceByCustomer}
              />
            : null}

            {this.state.showCopy ?
              <Copy 
                show={this.state.showCopy}
                close={() => this.setState({showCopy: false })}
                dataBooking={this.state.dataCopyBooking}
              />
            : null}

            {this.state.showDeleteBooking ?
              <Delete
                show={this.state.showDeleteBooking}
                deleteBooking={this.deleteBooking}
                dataBooking={this.state.dataBookingDelete}
                close={this.hidePopupDelete}
              />
            : null}

            {this.state.overlayLoading ?
              <div className="overlay-loading"><div className="icon-loading"></div></div>
            : null}
          </div>
        </div>
      );
    } else return null;
  }
}
export default Booking;
