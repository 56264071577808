import React from "react";
import { Link } from "react-router-dom";
import StorageService from "../utils/storage.service";
import axiosService from "../utils/axios.service";
import { Dropdown, Row, Col } from 'react-bootstrap';
import { NotificationContainer, } from "react-notifications";
import "../scss/header.scss";
import StoreHeader from "./stores/StoreHeader";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { ModalConfirmDaily } from './ModalConfirmDaily';

import _ from 'lodash';

const menuData = [
    { label: "Thu ngân", url: "/pos", icon: "fas fa-calculator", except: [2,4] },
    { label: "Khách hàng", url: "/customer", icon: "fas fa-female", except: [2] },
    {
        label: "Báo cáo",
        icon: "fas fa-chart-line",
        number: 5,
        submenu: [
            { 
                label: "Doanh thu",
                submenu: [
                    { label: "Danh sách hóa đơn", url: "/orders", except: [2] },
                    { label: "Doanh số theo ngày", url: "/report/daily", except: [2] },
                    { label: "Doanh số theo chi nhánh", url: "/report/locations", except: [2] },
                    { label: "Tiền thu từng dịch vụ", url: "/report/service-detail", except: [2] },
                ],
            },
            {
                label: "Nhân viên",
                submenu: [
                    { label: "Báo cáo tổng quan sale", url: "/report/online", include: [1] },
                    { label: "Báo cáo lễ tân", url: "/report/offline-lt", include: [1] },
                    { label: "Báo cáo tư vấn & phục vụ offline", url: "/report/offline-ktv-tvv", include: [1] },
                    { label: "Chi tiết sale", url: "/report/detail/sale-ctm", include: [1,5] },
                    { label: "Chi tiết KTV", url: "/report/detail/ktv-tvv", include: [1,2] }
                ],
            },
            {
                label: "Promotions",
                submenu: [
                    { label: "Hiệu quả promotion", url: "/report/promotion", except: [2] },
                    { label: "Chi tiết promotion", url: "/report/detail-promotion", except: [2] }
                ],
            },
            {
                label: "Kinh doanh",
                submenu: [
                    { label: "Vận hành cơ sở", url: "/report/operate-booking", include: [1] },
                    { label: "Kinh doanh cơ sở", url: "/report/business", include: [1] },
                    { label: "Tư vấn cơ sở theo ngày", url: "/report/ktv-tv-details", include: [1] },
                    { label: "Chi tiết từng KTV", url: "/report/ktv-tvv-detail", include: [1] },
                    { label: "Doanh thu dự kiến", url: "/report/total-price-booking", include: [1] },
                    { label: "Thi đua", url: "/report/emulation", include: [1] }
                ],
            },
            {
                label: "Khách hàng",
                submenu: [
                    { label: "Báo cáo dịch vụ phễu", url: "/report/hopper", include: [1] },
                ],
            },
        ],
    },
    { label: "Lấy voucher", url: "/create-voucher-lv3", icon: "fas fa-tag", except: [2] },
    { label: "Danh sách QR Code", url: "/report/qr-code", icon: "fas fa-qrcode", except: [2] },
    { label: "Thẻ khách hàng sở hữu", url: "/report/count/package-booking", icon: "fas fa-sd-card" },
];

const toggleSubMenu = (e) => {
    e.stopPropagation();
    let submenu = e.target.querySelector("[class ~= 'child-menu']");
    let parent = e.target

    if (e.target.nodeName === "I" || e.target.nodeName === "LABEL") {
        submenu = e.target.offsetParent.querySelector("[class ~= 'child-menu']");
        parent = e.target.offsetParent;
    } 

    if (!submenu) return;

    if (submenu.style.display === "none" || !submenu.style.display) {
      submenu.style.display = "block";
      parent.classList.add("active");
    } else {
      submenu.style.display = "none";
      parent.classList.remove("active");
    }
};

const renderSubMenuColumn = (subMenu, number, role) => {
    return (
        <div className="child-menu">
            <div className="row">
                {subMenu.map((subItem, index) => (
                    <div key={index} onClick={toggleSubMenu} className={`column`} style={{flex: "0 0 calc(100%/"+ number +")", maxWidth: "calc(100%/"+ number +")"}}>
                        <div className="border-bottom"><span className="dropText">{subItem.label}</span></div>
                        <div className="sub-menu-list">
                            {subItem.submenu.map((subMenuItem, subMenuIndex) => (
                                subMenuItem?.except ?
                                    !subMenuItem.except.includes(role) &&
                                        <div key={subMenuIndex} className="item-sub-menu">
                                            <Link to={{ pathname: subMenuItem?.url}}>
                                                {subMenuItem.label}
                                            </Link>
                                        </div>
                                        
                                : subMenuItem?.include ?
                                    subMenuItem.include.includes(role) &&
                                        <div key={subMenuIndex} className="item-sub-menu">
                                            <Link to={{ pathname: subMenuItem?.url}}>
                                                {subMenuItem.label}
                                            </Link>
                                        </div>
                                :
                                    <div key={subMenuIndex} className="item-sub-menu">
                                        <Link to={{ pathname: subMenuItem?.url}}>
                                            {subMenuItem.label}
                                        </Link>
                                    </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// const renderSubMenuList = (subMenu) => {
//     return (
//         <div className="child-menu">
//             {subMenu.map((subItem, index) => (
//                 <div key={index} onClick={toggleSubMenu}>
//                     <div class="border-bottom"><span class="dropText">{subItem.label}</span></div>
//                     {subItem.submenu && renderSubMenu(subItem.submenu)}
//                 </div>
//             ))}
//         </div>
//     );
// };

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            stores: [],
            userAdmin: JSON.parse(localStorage.getItem("user_info")),
            checkMobile: false,
            statusMenu: false,
            childBaoCao: false,
        };
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 768);
        if (currentHideNav !== this.state.checkMobile) {
          this.setState({ checkMobile: currentHideNav });
        }
      }

    logOut() {
        StorageService.delete("access_token");
        StorageService.delete("user_info");
        window.location.replace("/");
    }

    getDataStores() {

        let currentUrl = window.location.pathname
        if (this.state.userAdmin && this.state.userAdmin.stores.length > 0 && (this.state.userAdmin.role == 4 || (this.state.userAdmin.role == 3 && currentUrl.includes('report')))) {

            this.setState({ stores: this.state.userAdmin.stores })
            let currentStore = StorageService.getItem("currentStore")
            let positionStore = _.findIndex(this.state.userAdmin.stores, function (o) { return o.id == currentStore; });
            if (positionStore < 0) positionStore = 0
            StorageService.set("currentStore", this.state.userAdmin.stores[positionStore].id);
            StorageService.set("currentNameStore", this.state.userAdmin.stores[positionStore].name_store);

        } else {
            axiosService("api/stores", "GET", {}, this.props.token)
                .then((res) => {
                    const response = res.data
                    if (response.success) {
                        if (response.data !== null) {
                            let currentStore = StorageService.getItem("currentStore")
                            if (!currentStore) {
                                StorageService.set("currentStore", response.data[0].id ?? 1);
                                StorageService.set("currentNameStore", response.data[0].name_store ?? "Cent Beauty Trần Duy Hưng");
                            }
                            this.setState({ stores: response.data })
                        }
                    }
                }).catch((error) => console.log(error));
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize()
        this.getDataStores()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header-top">
                        <div className="header-left">
                            {this.props.type !== "crm" ?
                                <>  
                                    {this.state.checkMobile ? 
                                        <div className="icon-menu" onClick={() => this.setState({ statusMenu: !this.state.statusMenu })}>
                                            <i className="fas fa-bars"></i>
                                        </div>
                                    :null}
                                    <div className="menu-item logo">
                                        <Link to={{ pathname: this.state.userAdmin?.role != 2 ? "/dashboard" : "/booking" }}>
                                            <img src="/images/header/logo3.png" alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to={{ pathname: "/booking" }}>
                                            <i className="snake-gift fa fa-fas fa-calendar-alt"></i>
                                            Lịch hẹn
                                        </Link>
                                    </div>
                                    {/* {this.state.userAdmin && this.state.userAdmin.role != 2 ?
                                        <div className="menu-item">
                                            <Link to={{ pathname: "/pre-order" }}>
                                                <i className="fa fa-light fa-book"></i>
                                                Pre Order
                                            </Link>
                                        </div>
                                    : null} */}
                                    {/* <div className={`menu-list ${this.state.statusMenu ? "active" : ""}`}>
                                        {this.state.checkMobile ? 
                                            <div className="sidebar-head">
                                                <div className="tab_title">Menu</div>
                                            </div>
                                        :null}
                                        {this.state.userAdmin?.role != 2 && this.state.userAdmin.role != 4 ?
                                            <div className="menu-item">
                                                <Link to={{ pathname: "/pos" }}>
                                                    <i className="fas fa-calculator"></i>
                                                    Thu ngân
                                                </Link>
                                            </div>
                                        : null}
                                        {this.state.userAdmin.role != 2 ?
                                            <div className="menu-item">
                                                <Link to={{ pathname: "/customer" }}>
                                                    <i className="fas fa-female"></i>
                                                    Khách hàng
                                                </Link>
                                            </div>
                                        : null}
                                        <div className="menu-item">
                                            <Dropdown align={{ lg: 'end' }}>
                                                <Dropdown.Toggle className="button-drop" variant="#fff">
                                                    <i className="fas fa-chart-line"></i>
                                                    Báo cáo
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-report">
                                                    <Row className="p-2 dropdown-menu1">
                                                        <Col xs={6} md={3}>
                                                            {this.state.userAdmin.role != 2 ?
                                                                <div className="border-bottom">
                                                                    <span className="dropText">DOANH THU</span>
                                                                </div>
                                                            : null}
                                                            {this.state.userAdmin.role != 2 ?
                                                                <div>
                                                                    <ul className="mt-3 p-0">
                                                                        <Link to={{ pathname: "/orders" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-clipboard-list mr-1"></i><span>Danh sách hoá đơn</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/daily" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-chart-bar mr-1"></i><span>Doanh số theo ngày</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/locations" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-location-dot mr-1"></i><span>Theo chi nhánh</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/service-detail" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Tiền thu từng dịch vụ</span>
                                                                            </li>
                                                                        </Link>
                                                                    </ul>
                                                                </div>
                                                            : null}
                                                        </Col>
                                                        
                                                        
                                                        <Col xs={6} md={3}>
                                                            <div className="border-bottom">
                                                                <span className="dropText">NHÂN VIÊN</span>
                                                            </div>
                                                            <div>
                                                                {this.state.userAdmin?.role == 1 ?
                                                                    <ul className="mt-3 p-0">
                                                                        <Link to={{ pathname: "/report/online" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo tổng quan Sale</span>
                                                                        </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/offline-lt" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo lễ tân</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/offline-ktv-tvv" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo tư vấn & phục vụ offline</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/detail/sale-ctm" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết Sale</span>
                                                                            </li>
                                                                        </Link>
                                                                    </ul>
                                                                : null}
                                                            {this.state.userAdmin?.role == 5 ?
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/report/detail/sale-ctm" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết Sale</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            : null}
                                                            </div>
                                                            {this.state.userAdmin?.role == 2 || this.state.userAdmin?.role == 1 ?
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/report/detail/ktv-tvv" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết KTV</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            : null}
                                                        </Col>
                                                        
                                                        {this.state.userAdmin.role != 2 ?
                                                            <Col xs={6} md={2}>
                                                                <div className="border-bottom">
                                                                    <span className="dropText">Promotions</span>
                                                                </div>
                                                                <div>
                                                                    <ul className="mt-3 p-0">
                                                                        <Link to={{ pathname: "/report/promotion" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-clipboard-list mr-1"></i><span>Hiệu quả promotion</span>
                                                                            </li>
                                                                        </Link>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <ul className="mt-3 p-0">
                                                                        <Link to={{ pathname: "/report/detail-promotion" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-clipboard-list mr-1"></i><span>Chi tiết promotion</span>
                                                                            </li>
                                                                        </Link>
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        : null}
                                                        {this.state.userAdmin.role != 2 ?
                                                            <Col xs={6} md={2}>
                                                                <div className="border-bottom">
                                                                    <span className="dropText">Kinh doanh cơ sở</span>
                                                                </div>
                                                                <div>
                                                                    {this.state.userAdmin?.role == 1 ?
                                                                        <ul className="mt-3 p-0">

                                                                                <Link to={{ pathname: "/report/operate-booking" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Vận hành cơ sở</span>
                                                                                    </li>
                                                                                </Link>
                                                                                <Link to={{ pathname: "/report/business" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Kinh doanh cơ sở</span>
                                                                                    </li>
                                                                                </Link>

                                                                                <Link to={{ pathname: "/report/ktv-tv-details" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Tư vấn cơ sở theo ngày</span>
                                                                                    </li>
                                                                                </Link>

                                                                                <Link to={{ pathname: "/report/ktv-tvv-detail" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Bảng chi tiết từng KTV</span>
                                                                                    </li>
                                                                                </Link>

                                                                                <Link to={{ pathname: "/report/total-price-booking" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Doanh thu dự kiến</span>
                                                                                    </li>
                                                                                </Link>

                                                                                <Link to={{ pathname: "/report/emulation" }}>
                                                                                    <li className="text-li p-1">
                                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Thi đua</span>
                                                                                    </li>
                                                                                </Link>
                                                                                
                                                                        </ul>
                                                                    : null}
                                                                </div>
                                                            </Col>
                                                        : null}
                                                        {this.state.userAdmin.role != 2 ?
                                                            <Col xs={6} md={2}>
                                                                <div className="border-bottom">
                                                                    <span className="dropText">Customer</span>
                                                                </div>
                                                                <div>
                                                                    {this.state.userAdmin?.role == 1 ?
                                                                        <ul className="mt-3 p-0">
                                                                            <Link to={{ pathname: "/report/hopper" }}>
                                                                                <li className="text-li p-1">
                                                                                    <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo dịch vụ phễu</span>
                                                                                </li>
                                                                            </Link>
                                                                        </ul>
                                                                    : null}
                                                                </div>
                                                            </Col>
                                                        : null}
                                                    </Row>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="menu-item">
                                            <div className="title-menu">
                                                <i className="fas fa-chart-line"></i>
                                                Báo cáo
                                            </div>
                                            {this.state.childBaoCao ? 
                                                <div className="child-menu">
                                                    <div className="row">
                                                        {this.state.userAdmin.role != 2 ?
                                                            <div className="column-5">
                                                                <div className="border-bottom">
                                                                    <span className="dropText">DOANH THU</span>
                                                                </div>
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/orders" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-clipboard-list mr-1"></i><span>Danh sách hoá đơn</span>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to={{ pathname: "/report/daily" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-chart-bar mr-1"></i><span>Doanh số theo ngày</span>
                                                                        </li>
                                                                    </Link>
                                                                    <Link to={{ pathname: "/report/locations" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-location-dot mr-1"></i><span>Theo chi nhánh</span>
                                                                        </li>
                                                                    </Link>

                                                                    <Link to={{ pathname: "/report/service-detail" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-tags mr-1"></i><span>Tiền thu từng dịch vụ</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </div>
                                                        : null}
                                                        <div className="column"></div>
                                                        <div className="column-5"></div>
                                                        <div className="column-5"></div>
                                                        <div className="column-5"></div>
                                                    </div>
                                                </div>
                                            :null}
                                        </div>
                                        {this.state.userAdmin.role != 2 ?
                                            <div className="menu-item">
                                                <Link to={{ pathname: "/create-voucher-lv3" }}>
                                                    <i className="fas fa-tag"></i>
                                                    Lấy voucher
                                                </Link>
                                            </div>
                                        : null}
                                        {this.state.userAdmin.role != 2 ?
                                            <div className="menu-item">
                                                <Link to={{ pathname: "/report/qr-code" }}>
                                                    <i className="fas fa-qrcode"></i>
                                                    Danh sách QR Code
                                                </Link>
                                            </div>
                                        : null}
                                        <div className="menu-item">
                                            <Link to={{ pathname: "/report/count/package-booking" }}>
                                                <i className="fas fa-sd-card"></i>
                                                Thẻ khách hàng hiện có
                                            </Link>
                                        </div>
                                    </div> */}
                                    <div className={`menu-list ${this.state.statusMenu ? "active" : ""}`}>
                                        {this.state.checkMobile ? 
                                            <div className="sidebar-head">
                                                <div className="tab_title">Menu</div>
                                            </div>
                                        :null}
                                        {menuData.map((item, index) => (
                                            item?.except ?
                                                !item.except.includes(this.state.userAdmin.role) &&
                                                    <div key={index} onClick={toggleSubMenu} className={`menu-item ${item.submenu ? "parent-item" : ""}`}>
                                                        {item.url && item?.url !== "#" ?
                                                            <Link to={{ pathname: item?.url}}>
                                                                {item?.icon && <i className={item.icon}></i>}
                                                                {item.label}
                                                            </Link>
                                                        : 
                                                            <label className="title-menu">
                                                                {item?.icon && <i className={item.icon}></i>}
                                                                {item.label}
                                                            </label>
                                                        }
                                                        {(item.submenu && item?.number && item?.number > 0) && renderSubMenuColumn(item.submenu, item?.number, this.state.userAdmin.role)}
                                                    </div>
                                            : item?.include ?
                                                item.include.includes(this.state.userAdmin.role) &&
                                                    <div key={index} onClick={toggleSubMenu} className={`menu-item ${item.submenu ? "parent-item" : ""}`}>
                                                        {item.url && item?.url !== "#" ?
                                                            <Link to={{ pathname: item?.url}}>
                                                                {item?.icon && <i className={item.icon}></i>}
                                                                {item.label}
                                                            </Link>
                                                        : 
                                                            <label className="title-menu">
                                                                {item?.icon && <i className={item.icon}></i>}
                                                                {item.label}
                                                            </label>
                                                        }
                                                        {(item.submenu && item?.number && item?.number > 0) && renderSubMenuColumn(item.submenu, item?.number, this.state.userAdmin.role)}
                                                    </div>
                                            :
                                                <div key={index} onClick={toggleSubMenu} className={`menu-item ${item.submenu ? "parent-item" : ""}`}>
                                                    {item.url && item?.url !== "#" ?
                                                        <Link to={{ pathname: item?.url}}>
                                                            {item?.icon && <i className={item.icon}></i>}
                                                            {item.label}
                                                        </Link>
                                                    : 
                                                        <label className="title-menu">
                                                            {item?.icon && <i className={item.icon}></i>}
                                                            {item.label}
                                                        </label>
                                                    }
                                                    {(item.submenu && item?.number && item?.number > 0) && renderSubMenuColumn(item.submenu, item?.number, this.state.userAdmin.role)}
                                                </div>
                                        ))}
                                    </div>
                                    {this.state.checkMobile ? 
                                        <>
                                            <div className={`overlay-menu ${this.state.statusMenu ? "active" : ""}`} onClick={() => this.setState({ statusMenu: !this.state.statusMenu })}></div>
                                            <div className={`close-button-menu ${this.state.statusMenu ? "active" : ""}`} onClick={() => this.setState({ statusMenu: !this.state.statusMenu })}>
                                                <svg role="presentation" viewBox="0 0 16 14">
                                                    <path d="M15 0L1 14m14 0L1 0" stroke="currentColor" fill="none" fillRule="evenodd"></path>
                                                </svg>
                                            </div>
                                        </>
                                    :null}
                                </>
                            : null}
                        </div>
                        <div className="header-right">
                            {this.props.type != "crm" ? 
                                <>
                                    <StoreHeader stores={this.state.stores} checkMobile={this.state.checkMobile} />
                                    <div className="my-account">
                                        <Dropdown align={{ lg: 'end' }}>
                                            <Dropdown.Toggle className="button-drop" variant="#fff" style={{ "paddingBottom": "0px" }}>
                                                <Avatar size={30} icon={<UserOutlined />} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ left: "auto", right: "0px", position: "absolute" }}>
                                                <Dropdown.Item>
                                                    {this.state.userAdmin.name}
                                                </Dropdown.Item>
                                                <Dropdown.Item href={"/change-password"}>
                                                    <i className="fas fa-lock"></i><span className="ml-1 mt-2">Đổi mật khẩu</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.logOut() }}>
                                                    <i className="fa-solid fa-arrow-right-from-bracket"></i><span className="ml-1 mt-2">Đăng xuất</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* </div> */}
                                    </div>
                                </> 
                            : null}
                        </div>
                    </div>
                    <NotificationContainer />
                    <ModalConfirmDaily
                        show={true}
                    ></ModalConfirmDaily>
                </div>

            </React.Fragment>
        );
    }
}
export default Header;
