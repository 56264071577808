import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"

import { format, startOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "../scss/report.scss"
import { Table, Spin, Button } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import CurrencyInput from 'react-currency-input-field';
import { Input} from 'antd';
import { notifications } from "../../constant/Notify";
import StorageService from "../../../utils/storage.service";
import Loading from "../../customer/Loading";

const ReportKtvTvvDetailDate = (props) => {
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [monthTotal, setMonthTotal] = useState(0)
    const [note, setNote] = useState('')
    const [table, setTable] = useState([...props.table])
    useEffect(() => {
        setTable([...props.table])
    }, [props.table])
    const onChangeMucTieu = (e,d) => {
     
        let data = e.target.value.replaceAll(",", "");
        let tables = []
        for (let item of table) {
            if (item.user_id == d.user_id) {
                item.taget_month = Number(data)
            }
            tables.push(item)
        }
        setTable([...tables])
        setMonthTotal(Number(data))
    }

    const onChangeNote = (e) => {
        console.log(e)
    }

    const saveEditOrderCode = (e) => {
        const data = {
            "user_id": e.user_id,
            "user_name": e.user_name,
            "target": monthTotal
        }
        axiosService("api/daily-target/month/"+ e.user_id,"POST", data)
            .then((res)=> {
                const response = res.data;
                notifications({ type: 'success', mess: 'Thêm mục tiêu tháng thành công'});
            })
        
    }
 
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    var columns = [
        { dataIndex: 'user_name', title: 'Nhân viên', width: 250  },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            // dataIndex: 'total_price',
            title: 'Mục tiêu tháng',
            width: 150,
            align: "center",
            render: (data) => {
                return (
                    <> 
                       <p className="text4">
                            <CurrencyInput id={data.user_id} onChange={(e) =>onChangeMucTieu(e, data)} defaultValue={data.taget_month} value={data.taget_month} /><i onClick={(e) => saveEditOrderCode(data)} style={{cursor: 'pointer'}} className="fas fa-save"></i>
                        </p> 
                    </>
               )
            },
        },
        {
            // dataIndex: 'total_price',
            title: 'Tổng',
            width: 350,
            align: "center",
            render: (data) => {
                let total_taget = data['total_taget']
                let total_paid = data['total_paid']
                let tiLe = '0'
                if (data['total_taget'] > 0 && data['total_paid'] > 0) {
                  
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid ?? 0)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
    ];
    
    for(let item of props.keyTable) {
        let row = {
            title: item,
            width: 250,
            align: "center",
            render: (data) => {
                let total_taget = 0
                let total_paid = 0
                let tiLe = '0'
                if (data[item] != undefined ) {
                    total_taget = data[item]['total_taget']
                    total_paid = data[item]['paidOrder']
                    if(data[item]['total_taget'] > 0 && data[item]['paidOrder'] > 0) {
                        tiLe = ((total_taget/total_paid)*100).toFixed(1)

                    }
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid ?? 0)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        }
        columns.push(row)
    }

    const handelExportUser = () => {
        // if (_state.loadingShowClickItem) return
        setShowloading(true)
      
        var url = `api/mysql/export/detail-user?type=1&user_id=${props.userId}&user_name=${props.userName}&start=${props.startDate}&end=${props.endDate}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
    const  handleChange = (e) => {
        setPageSize(e.pageSize)
    }

  
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="p-2">
                
                <div className="border-bottom w-100 pb-2">
                    <Row>
                        <Col xs={6}>
                            <div>
                                Bảng số liệu chi tiết
                            </div>
                        </Col>
                        <Col xs={6}>
                            <Button size="middle" type="primary"
                             onClick={handelExportUser}
                            ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                            </Button>
                        </Col>
                    </Row>
                </div>
                    
                <Table 
                    columns={columns} 
                    dataSource={table}
                    pagination={{ pageSize: pageSize, showSizeChanger: true, pageSizeOptions: ['10', '15', '30', '50', '100'] }}
                    scroll={{ x: columns.length*200 }}
                    onChange={handleChange}
                    bordered
                />
            </div>
            <Loading
                show={showloading}
            ></Loading>
        </>
    )
}
export default ReportKtvTvvDetailDate