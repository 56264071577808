import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as convert from "../constant/Convert.helper"
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select';
import { reasonEditCredit2, reasonEditCreditAdmin2 } from './constant/CustomerBirthday'
import { dataUpgrade } from './constant/upgrade'
import axiosService from "../../utils/axios.service";
import * as generalData from "../constant/GeneralData"
import {PopupConfirm} from "./PopupConfirm"
import Avatar from 'react-avatar';
import { notifications } from "../constant/Notify";
import _ from "lodash"

export function EditCredit2(props) {
  const history = useHistory();
  const [dataEditCredit, setDataEditCredit] = useState({
    "change_price": 0,
    "store_id": localStorage.getItem("currentStore") ?? "",
    "store_name": localStorage.getItem("currentNameStore") ?? 0,
    "created_by": JSON.parse(localStorage.getItem("user_info")) && JSON.parse(localStorage.getItem("user_info")).id ? JSON.parse(localStorage.getItem("user_info")).id : "",
    "created_by_name": JSON.parse(localStorage.getItem("user_info")) && JSON.parse(localStorage.getItem("user_info")).name ? JSON.parse(localStorage.getItem("user_info")).name : ""
  });
  const user = useMemo(() => {
    const getDataUserLocalStorage = localStorage.getItem("user_info")
    return JSON.parse(getDataUserLocalStorage)
  })
  const [keywordSearchCustomer, setKeyWorkSearchCustomer] = useState('');
  const [dataSearchCustomer, setDataSearchCustomer] = useState([]);
  const [newDeposit, setNewDeposit] = useState(props.detailCustomer.deposit_money);
  const [newGiftMoney, setNewGiftMoney] = useState(props.detailCustomer.gift_money)
  const [activeButtonSave, setActiveButtonSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packages, setPackage] = useState([])
  const [selectPackages, setSelectPackages] = useState([])
  const [listPackageBeforeUpgrade, setListPackageBeforeUpgrade] = useState([])
  const [listPackageConvert, setListPackageConvert] = useState([])
  const [listPackageRefund, setListPackageRefund] = useState([])
  const [listPackageAfterUpgrade, setListPackageAfterUpgrade] = useState([])
  const [packageBeforeUpgrade, setPackageBeforeUpgrade] = useState(null)
  const [packageAfterUpgrade, setPackageAfterUpgrade] = useState(null)
  const [confirmPopup, setConfirmPopup] = useState(false)

  const [listWaxingBeforeUpgrade, setListWaxingBeforeUpgrade] = useState([])
  const [listWaxingAfterUpgrade, setListWaxingAfterUpgrade] = useState([])
  const [waxingBeforeUpgrade, setWaxingBeforeUpgrade] = useState(null)
  const [waxingAfterUpgrade, setWaxingAfterUpgrade] = useState(null)

  const [listWaxingNewBeforeUpgrade, setListWaxingNewBeforeUpgrade] = useState([])
  const [listWaxingNewAfterUpgrade, setListWaxingNewAfterUpgrade] = useState([])
  const [waxingNewBeforeUpgrade, setWaxingNewBeforeUpgrade] = useState(null)
  const [waxingNewAfterUpgrade, setWaxingNewAfterUpgrade] = useState(null)

  const [listWaxingFullOld, setListWaxingFullOld] = useState([])
  const [listWaxingFullNew, setListWaxingFullNew] = useState([])
  const [listWaxingFullOldChoose, setListWaxingFullOldChoose] = useState([])
  const [listWaxingFullNewChoose, setListWaxingFullNewChoose] = useState([])
  const listProductOld = [701,702,703,704,705,706,707,708,709,710,711,712,713,714,715,716,717,718,719,720,529,531,533,535,537,539,541,543,545,547,549,551,553,555,557,559,561,563,565,570,677]


  useEffect(() => {
    const { listPackage } = props
    let filter = [],
        listWaxingFullOld = [],
        listPackageBeforeUpgrade = [],
        listWaxingBeforeUpgrade = [],
        listWaxingNewBeforeUpgrade = [],
        listPackageConvert = [],
        listPackageRefund = [];

    listPackage.map((item, key) => {
      let catId = item?.product?.category_id;
      if (item.gift_type === 0) {
        if (item.is_convert_bhvv != 1 && !item.rules?.includes("combo") && item.order_id) {
          let paidOrder = 0
          paidOrder = _.sumBy(item.order.transaction, function(o) { return (!o.soft_delete) ? o.paid_amount : 0; });
          if (paidOrder === item.order.total_price) {
            // Danh sách thẻ buổi khác triệt (da, phun xăm...)
            if (catId != 5 && item?.upgrade_id?.length > 0 ) listPackageBeforeUpgrade.push(item)

            // Danh sách nhượng thẻ
            if ([5, 4].includes(catId) && item.count_used === 0) filter.push(item)
            if (![5, 4].includes(catId)) filter.push(item)
            
            //Chuyển đổi và hoàn tiền
            if (item.order && Number(item.order.money_owed) == 0) {
              if (catId == 5) {
                if (item.day_use <= 45) listPackageConvert.push({...item})
              } else {
                listPackageConvert.push({...item})
              }
              if (item.day_use <= 45) listPackageRefund.push({...item})
            }
          
            // Danh sách thẻ triệt mới
            if (catId == 5 && item?.product?.new_waxing >= 2) listWaxingNewBeforeUpgrade.push(item)
          }
        }

        // Danh sách thẻ triệt cũ
        if (catId == 5 && item?.product?.new_waxing === 0) {
          if (item?.product.product_name.includes("Full body")) {
            listWaxingFullOld.push(item)
          } else {
            if (item.product.id !== 816) listWaxingBeforeUpgrade.push(item)
          }
        }
      }
    })

    listPackageRefund.map((item, key) => {
      let fees = 0
      if (item.day_by <= 15 && item.count_used == 0) {
        fees = 0
      } else {
        fees = item.left_price*20/100
      }
      listPackageRefund[key].left_price = item.left_price - fees
    })

    var grouped = {}
    for (let item of listPackage) {
      if (item.rules !== "" && item.rules?.includes("combo")) {
        let key = item.rules + ',' + item.order_id
        
        if (!grouped[key] && typeof grouped[key] == 'undefined') {
          grouped[key] = [item]
        } else {
          grouped[key].push(item)
        }
      }
    }
    const mapObject = []
    for (const [key, value] of Object.entries(grouped)) {
      const mapName = value.map(x => { return x.package_code + ' - ' + x.product_name })
      let count = _.countBy(value, (x) => { return x.count_used < 3 })
      let countDayUse = _.countBy(value, (x) => { return x.day_use <= 45 })
      
      if (count.true == value.length && countDayUse.true ==  value.length) {
        let listProduct = _.map(value, 'product_id');
        listProduct = listProduct.sort((a, b) => a - b);
        
        mapObject.push({
          id: key,
          label: `Combo (${mapName.join(", ")})`,
          type: "rule",
          id_select: listProduct.join("-"),
          value: value,
          order: value[0].order,
          day_by:  value[0].day_by
        })
      }
    }

    setListWaxingFullOld(listWaxingFullOld)
    setListPackageBeforeUpgrade(listPackageBeforeUpgrade)
    setListWaxingBeforeUpgrade([...listWaxingBeforeUpgrade, ...mapObject])
    setListWaxingNewBeforeUpgrade(listWaxingNewBeforeUpgrade)
    setListPackageConvert([...listPackageConvert, ...mapObject])
    setListPackageRefund([...listPackageRefund, ...mapObject])
    setPackage([...filter, ...mapObject])
  }, [props.listPackage])

  const changeReason = (event) => {
    let data = []
    setSelectPackages([...data])
    setListPackageAfterUpgrade([])
    let price = Number(dataEditCredit.change_price);
    if (event.value == "Chuyển đổi thẻ") {
      price = 0;
      setActiveButtonSave(dataEditCredit.package_code ? true : false)
    }
    if (event.value == "Chuyển cọc") {
      setActiveButtonSave(dataEditCredit.receiver_customer && dataEditCredit.change_price ? true : false)
      if (price > Number(props.detailCustomer.deposit_money)) price = Number(props.detailCustomer.deposit_money)
      setNewDeposit(Number(props.detailCustomer.deposit_money) - price)
    } else {
      setNewDeposit(Number(props.detailCustomer.deposit_money) + price)
    }

    if (event.value == "Quà tặng") setActiveButtonSave(dataEditCredit.change_price ? true : false)
    let reason = event.value

    setSelectPackages([])

    setDataEditCredit({
      ...dataEditCredit,
      reason: reason,
      change_price: price,
      package_code: (event.value != "Chuyển đổi thẻ") ? null : dataEditCredit.package_code,
      package_name: dataEditCredit.package_name,
      receiver_customer: (event.value != "Chuyển cọc") ? null : dataEditCredit.receiver_customer
    })
  }

  const changePackage = (event) => {
    if (event.type && event.type == "rule") {
      const { value } = event
      let total = 0
      let packageCodes = ""
      let checkUsePackage = false
      let dayUse = 0
      value.forEach(x => {
        let price = x.rule_price > 0 ? x.rule_price : x.initial_amount;
        if (x.metaPrice) {
          total += price - (Number(x.metaPrice) * x.count_used);
        } else {
          total += (price / x.max_used) * (x.max_used - x.count_used);
        }
        if (x.count_used > 0) {
          checkUsePackage = true
        }
        packageCodes += x.package_code + ","
      })
      setSelectPackages([...value])
      setNewDeposit(Number(props.detailCustomer.deposit_money) + Number(total))
      setNewGiftMoney(Number(props.detailCustomer.gift_money) + Number(total))
      setActiveButtonSave(packageCodes.length > 0 ? true : false)
      if (dataEditCredit.reason == "Hoàn tiền" ) {
        if (checkUsePackage || event.day_by > 15) {
          total -= total*20/100 
        }
      }
      setDataEditCredit({
        ...dataEditCredit,
        change_price: total,
        money_wallet: event.money_wallet,
        package_code: packageCodes,
        package_name: event.label,
        order: event.order
      })
    } else {
      let total = event.left_price
      setNewDeposit(Number(props.detailCustomer.deposit_money) + Number(event.left_price))
      setActiveButtonSave(event.package_code ? true : false)
      setNewGiftMoney(props.detailCustomer.gift_money + event.money_wallet)
      setDataEditCredit({
        ...dataEditCredit,
        change_price: total,
        money_wallet: event.money_wallet,
        package_code: event.package_code,
        order: event.order,
        package_name: event.label
      })
      setSelectPackages([event])
    }
  }

  const changePackageBeforeUpgrade = async (event) => {
    setPackageAfterUpgrade(null);
    setActiveButtonSave(false);
    setPackageBeforeUpgrade(event)
    let upgrade_id = event.upgrade_id
    let response = await axiosService("api/package/package-upgrade", "POST", upgrade_id);
    if (response.data.success !== true) return
    if (response.data.data.length > 0) {
      let newProduct = []
      response.data.data.map(item => {
        newProduct.push({
          ...item,
          value: item.id,
          label: item.product_name
        })
      })
      setListPackageAfterUpgrade(newProduct)
    }
  }

  const changePackageAfterUpgrade = async (event) => {
    setPackageAfterUpgrade(event);
    setActiveButtonSave(true);
  }

  const changeWaxingBeforeUpgrade = async (event) => {
    setListWaxingAfterUpgrade([])
    setWaxingAfterUpgrade(null)
    setActiveButtonSave(false);

    let listProductUpgrade = []
    if (event.type == "rule") {
      listProductUpgrade = dataUpgrade[event.id_select] ?? []
    } else {
      if (listProductOld.includes(event.product_id)) {
        listProductUpgrade = [
          { value: '803', label: 'Triệt lông Premium - 10 buổi' },
          { value: '825', label: 'Triệt lông - 8 vùng - 10 buổi' }
        ]
      } else {
        let upgrade_id = event.upgrade_id
        if (upgrade_id) {
          let response = await axiosService("api/package/package-upgrade", "POST", upgrade_id);
          if (response.data.success !== true) return
          if (response.data.data.length > 0) {
            response.data.data.map(item => {
              listProductUpgrade.push({
                ...item,
                value: item.id,
                label: item.product_name
              })
            })
          }
        }
      }
    }

    setWaxingBeforeUpgrade([event])
    setListWaxingAfterUpgrade(listProductUpgrade)
  }

  const changeWaxingFullOld = async (event) => {
    setListWaxingFullNew([])
    setListWaxingFullOldChoose(null)
    setActiveButtonSave(false);
    let listPackage = [];
    let listWaxingFullNew = [{ value: '813', label: 'Triệt lông Full Body Premium - 10 buổi' }]
    event.map(item => {
      if (item.type == 'rule') {
        listPackage.push(...item.value)
      } else {
        listPackage.push(item)
      }
    })

    setListWaxingFullOldChoose(listPackage)
    setListWaxingFullNew(listWaxingFullNew)
  }

  const changeWaxingFullNew = async (event) => {
    setListWaxingFullNewChoose(event);
    setActiveButtonSave(true);
  }

  const changeWaxingNewBeforeUpgrade = async (event) => {
    setListWaxingNewAfterUpgrade([])
    setWaxingNewAfterUpgrade(null)
    setActiveButtonSave(false);
    let upgrade_id = event.upgrade_id
    let newProduct = []
    if (upgrade_id) {
      let response = await axiosService("api/package/package-upgrade", "POST", upgrade_id);
      if (response.data.success !== true) return
      if (response.data.data.length > 0) {
        response.data.data.map(item => {
          newProduct.push({
            ...item,
            value: item.id,
            label: item.product_name
          })
        })
      }
    }

    setWaxingNewBeforeUpgrade([event])
    setListWaxingNewAfterUpgrade([...newProduct])
    
  }

  const changeWaxingAfterUpgrade = async (event) => {
    setWaxingAfterUpgrade(event);
    setActiveButtonSave(true);
  }

  const changeWaxingNewAfterUpgrade = async (event) => {
    setWaxingNewAfterUpgrade(event);
    setActiveButtonSave(true);
  }

  const setShowPopupConfirm = async (e) => {
    setConfirmPopup(!confirmPopup)
  }

  const changePrice = (event) => {
    let dataPrice = event.target.value.replaceAll(".", "");
    if(dataEditCredit.reason ==="Quà tặng"){
      setNewGiftMoney(Number(props.detailCustomer.gift_money) + Number(dataPrice))
    }else if (dataEditCredit.reason != "Chuyển cọc") {
      setNewDeposit(Number(props.detailCustomer.deposit_money) + Number(dataPrice))
    } else {
      if (Number(dataPrice) > Number(props.detailCustomer.deposit_money)) dataPrice = Number(props.detailCustomer.deposit_money)
      setNewDeposit(Number(props.detailCustomer.deposit_money) - Number(dataPrice))
    }

    setDataEditCredit({
      ...dataEditCredit,
      change_price: dataPrice
    })

    if (dataEditCredit.reason == "Chuyển cọc") {
      setActiveButtonSave(dataEditCredit.receiver_customer && dataPrice ? true : false)
    } else {
      setActiveButtonSave(dataPrice ? true : false)
    }
  }

  const changeNote = (event) => {
    setDataEditCredit({
      ...dataEditCredit,
      note: event.target.value
    })
  }

  const searchCustomer = async (event) => {
    let keyword = event.target.value;
    if (keyword.length >= 2) {
      let res = await axiosService("api/customer?keyword=" + keyword, "GET")
      setDataSearchCustomer(res.data.data.data)
    }
    setKeyWorkSearchCustomer(keyword)
  }

  const getDetailCustomer = (data) => {
    setKeyWorkSearchCustomer('')
    setActiveButtonSave(true)
    setDataEditCredit({
      ...dataEditCredit,
      receiver_customer: data
    })
  }

  const addNewData = async () => {
    setLoading(true)
    if (dataEditCredit.reason == "Chuyển cọc" && !dataEditCredit.receiver_customer) return false;

    let dataCredit = {
      "customer_id": props.detailCustomer.id,
      "customer_name": props.detailCustomer.full_name,
      "customer_mobile": props.detailCustomer.mobile,
      "change_price": Number(dataEditCredit.change_price),
      "money_wallet": Number(dataEditCredit.money_wallet),
      "note": dataEditCredit.note ?? null,
      "reason": dataEditCredit.reason,
      "store_id": dataEditCredit.store_id,
      "store_name": dataEditCredit.store_name,
      "created_by": dataEditCredit.created_by,
      "created_by_name": dataEditCredit.created_by_name,
      "order_id": dataEditCredit.order ? dataEditCredit.order.id : null,
      "order_total_price": dataEditCredit.order ? dataEditCredit.order.order_total_price : null,
    }
  
    if (dataEditCredit.receiver_customer) dataCredit.receiver_id = dataEditCredit.receiver_customer.id;
    if (dataEditCredit.package_code) {
      dataCredit.package_code = dataEditCredit.package_code
      dataCredit.package_name = dataEditCredit.package_name
    }

    let saveCredit
    if (dataEditCredit.reason == "Nhượng thẻ") {
      if (!dataEditCredit.receiver_customer) {
        notifications({ type: 'error', mess: 'Thiếu thông tin khách hàng' });
        setLoading(false)
        return false
      }
      dataEditCredit.receiver_customer['note_package'] = dataEditCredit.note
      dataCredit['customer_new'] =  dataEditCredit.receiver_customer
      saveCredit = await axiosService("api/package/customer/" +  props.listPackage[0].id, "PUT", dataCredit);
    } else if (dataEditCredit.reason == "Hoàn tiền") {
      saveCredit = await axiosService("api/package/refund/customer", "POST", dataCredit);
    }
    else {
      saveCredit = await axiosService("api/credit", "POST", dataCredit);
    }

    if (saveCredit.data.success == true) {
      props.onHandelPopup()
      if (dataEditCredit.reason != "Nhượng thẻ") {
        props.changeCurrenDeposit(newDeposit, props.detailCustomer.gift_money)
        if (typeof props.handleStatusPackage != "undefined" ) {
          props.handleStatusPackage({id: props.listPackage[0].id, status: 2})
        }
      }
      notifications({ type: 'success', mess: 'Cập nhật thành công' });
    } else {
      notifications({ type: 'error', mess: saveCredit.data.message });
    }
  }

  const saveUpgradePackage = async () => {
    setLoading(true)
    let url = '';
    let data = {
      customer: props.detailCustomer,
      store_id: dataEditCredit.store_id,
      created_by: dataEditCredit.created_by,
      created_name: dataEditCredit.created_by_name
    }

    if (dataEditCredit.reason == "Nâng cấp thẻ buổi") {
      data.packageBefore = packageBeforeUpgrade;
      data.packageAfter = packageAfterUpgrade;
      url = 'api/orders/upgrade-package';
    }

    if (dataEditCredit.reason == "Nâng cấp thẻ triệt premium") {
      data.packageBefore = waxingNewBeforeUpgrade;
      data.packageAfter = waxingNewAfterUpgrade;
      url = 'api/orders/upgrade-waxing-package';
    }

    if (dataEditCredit.reason == "Nâng cấp thẻ triệt") {
      data.packageBefore = waxingBeforeUpgrade;
      data.packageAfter = waxingAfterUpgrade;
      
      url = 'api/orders/upgrade-waxing-type-new-package';
      if (listProductOld.includes(data.packageBefore[0].product_id)) {
        url = 'api/orders/upgrade-old-waxing-package';
      }
    }

    if (dataEditCredit.reason == "Nâng cấp thẻ triệt fullbody") {
      data.packageBefore = listWaxingFullOldChoose;
      data.packageAfter = listWaxingFullNewChoose;
      url = 'api/orders/upgrade-full-waxing-package';
    }

    if (url == '') {
      notifications({ type: 'error', mess: "Đã có lỗi, vui lòng thử lại" });
      setLoading(false)
      return false
    } 
    let dataUpgrade = await axiosService(url, "POST", data);
    if (dataUpgrade.data.success == true) {
      history.push("/pos")
      window.location.reload()
    } else {
      notifications({ type: 'error', mess: dataUpgrade.data.message });
      setLoading(false)
    }
    // setLoading(false)
  }
  return (
    <div className="order-popup edit-credit">
      <div className="popup-cent">
        <div className="content-popup fadeInDown">  
          <div className="head-popup">
            <div className="title">
              Thao tác thẻ
            </div>
          </div>
          <div className="body-popup">
            <div className="panel-body">
              <div className="row item-popup">
                <div className="col-6 col-md-3">Số tiền trong tài khoản</div>
                <div className="col-6 col-md-3">{convert.convertCurrency(props.detailCustomer.deposit_money)}</div>

                <div className="col-6 col-md-3">Số điểm tích luỹ</div>
                <div className="col-6 col-md-3">{convert.convertCurrency(props.detailCustomer.gift_money)}</div>
              </div>
              <div className="row item-popup">
                <div className="col-3">Lý do: </div>
                <div className="col-9">
                  <Select className="select" onChange={changeReason} placeholder="Vui lòng chọn lý do" options={(user.role == 1) ? reasonEditCreditAdmin2 : reasonEditCredit2} />
                </div>
              </div>
              {dataEditCredit.reason && ["Chuyển đổi thẻ", "Nhượng thẻ", "Hoàn tiền"].includes(dataEditCredit.reason) ?  
                <div className="row item-popup">
                  <div className="col-3">Chọn thẻ</div>
                  <div className="col-9">
                    {dataEditCredit.reason == "Chuyển đổi thẻ" ?
                      <Select className="select" onChange={changePackage} placeholder="Vui lòng chọn thẻ muốn chuyển đổi" options={listPackageConvert} />
                    : dataEditCredit.reason == "Hoàn tiền" ? 
                      <Select className="select" onChange={changePackage} placeholder="Vui lòng chọn thẻ muốn hoàn tiền" options={listPackageRefund} /> 
                    : <Select className="select" onChange={changePackage} placeholder="Vui lòng chọn thẻ muốn nhượng thẻ" options={packages} />}
                  </div>
                </div>
              : null}

              {dataEditCredit.reason && dataEditCredit.reason == "Nâng cấp thẻ buổi" ?  
                <div className="item-popup">
                  <div className="row">
                    <div className="col-3">Chọn thẻ cần nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changePackageBeforeUpgrade} placeholder="Vui lòng chọn thẻ cần nâng cấp" options={listPackageBeforeUpgrade} />
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-3">Chọn thẻ muốn nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changePackageAfterUpgrade} placeholder="Vui lòng chọn thẻ muốn nâng cấp" value={packageAfterUpgrade} options={listPackageAfterUpgrade} />
                    </div>
                  </div>
                </div>
              : null}

              {dataEditCredit.reason && dataEditCredit.reason == "Nâng cấp thẻ triệt" ?  
                <div className="item-popup">
                  <div className="row">
                    <div className="col-3">Chọn thẻ cần nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingBeforeUpgrade}
                        placeholder="Vui lòng chọn thẻ cần nâng cấp"
                        options={listWaxingBeforeUpgrade}
                        menuPlacement="bottom"
                      />
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-3">Chọn thẻ muốn nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingAfterUpgrade} placeholder="Vui lòng chọn thẻ muốn nâng cấp" value={waxingAfterUpgrade} options={listWaxingAfterUpgrade} />
                    </div>
                  </div>
                </div>
              : null}
              {dataEditCredit.reason && dataEditCredit.reason == "Nâng cấp thẻ triệt premium" ?  
                <div className="item-popup">
                  <div className="row">
                    <div className="col-3">Chọn thẻ cần nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingNewBeforeUpgrade}
                        placeholder="Vui lòng chọn thẻ cần nâng cấp"
                        options={listWaxingNewBeforeUpgrade}
                        menuPlacement="bottom"
                        closeMenuOnSelect={true}
                      />
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-3">Chọn thẻ muốn nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingNewAfterUpgrade} placeholder="Vui lòng chọn thẻ muốn nâng cấp" value={waxingNewAfterUpgrade} options={listWaxingNewAfterUpgrade} />
                    </div>
                  </div>
                </div>
              : null}

              {dataEditCredit.reason && dataEditCredit.reason == "Nâng cấp thẻ triệt fullbody" ?  
                <div className="item-popup">
                  <div className="row">
                    <div className="col-3">Chọn thẻ cần nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingFullOld}
                        placeholder="Vui lòng chọn thẻ cần nâng cấp"
                        options={listWaxingFullOld}
                        isMulti menuPlacement="bottom"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-3">Chọn thẻ muốn nâng cấp</div>
                    <div className="col-9">
                      <Select className="select" onChange={changeWaxingFullNew} placeholder="Vui lòng chọn thẻ muốn nâng cấp" value={listWaxingFullNewChoose} options={listWaxingFullNew} />
                    </div>
                  </div>
                </div>
              : null}
              { selectPackages.length > 0 ?
                  <div className="row item-popup">
                    <div className="col-3">Giá bán </div>
                    <div className="col-9">
                      {selectPackages.map(x => {
                        return <p key={x.id}>{`${x.product_name}(${x.count_used + "/" + x.max_used}): ${convert.convertCurrency(x.rule_price > 0 ? x.rule_price : x.initial_amount)}`}</p>
                      })}
                    </div>
                  </div>
              : null }
              {(dataEditCredit.reason && dataEditCredit.reason == "Chuyển cọc" || dataEditCredit.reason == "Nhượng thẻ") ?
                <div className="row item-popup">
                  <div className="col-3">Chọn khách hàng nhận</div>
                  <div className="col-9">
                    <div className="receiver-customer">
                      <input
                        name="receiver-customer"
                        onChange={searchCustomer}
                        defaultValue={keywordSearchCustomer}
                        className="input-credit receiver-customer"
                      />
                      {keywordSearchCustomer != '' ?
                        <div className="result-search">
                          {dataSearchCustomer.length === 0 ?
                            <div className="empty-customer">
                              Không tìm thấy dữ liệu phù hợp
                            </div>
                            :
                            <div className="items-customer">
                              {dataSearchCustomer.map((data, key) => (
                                <div className="item" key={key} onClick={() => { getDetailCustomer(data) }}>
                                  <div className="avatar">
                                    {data.avata_url ?
                                      <img src={data.avata_url} alt="" />
                                      : <Avatar name={data.full_name} size="36" textSizeRatio={1.75} round="50%" />}
                                  </div>
                                  <div className="info">
                                    <div className="name">
                                      {data.full_name}
                                      {data?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                    </div>
                                    {data.ranking ? <span className="ranking">({generalData.optionsRanking[data.ranking - 1].label})</span> : null}
                                    <div className="phone">{data.mobile}</div>
                                    <div className="address">{data.district} - {data.city}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                : null}
              {dataEditCredit.receiver_customer?
                <div className="row item-popup">
                  <div className="col-3"></div>
                  <div className="col-9">
                    <div className="row receiver_customer">
                      <div className="col-3 col-md-2 avatar">
                        {dataEditCredit.receiver_customer.avata_url ?
                          <img src={dataEditCredit.receiver_customer.avata_url} alt="" />
                          : <Avatar name={dataEditCredit.receiver_customer.full_name} size="60" textSizeRatio={1.75} round="50%" />}
                      </div>
                      <div className="col-9 col-md-10 info-customer">
                        <div className="top-info">
                          <span className="gender">{dataEditCredit.receiver_customer.gender === 1 ? "Anh" : "Chị"}</span>
                          <span className="name">{dataEditCredit.receiver_customer.full_name}</span>
                          {dataEditCredit.receiver_customer.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                        </div>
                        {dataEditCredit.receiver_customer.ranking ?
                          <div className="ranking"><i className="fas fa-trophy"></i>{generalData.optionsRanking[dataEditCredit.receiver_customer.ranking - 1].label}</div>
                          : null}
                        <div className="phone"><i className="fas fa-phone-square"></i>{dataEditCredit.receiver_customer.mobile}</div>
                        <div className="address"><i className="fas fa-map-marker-alt"></i>{dataEditCredit.receiver_customer.district} - {dataEditCredit.receiver_customer.city} </div>
                      </div>
                    </div>
                  </div>
                </div>
              : null}
              {dataEditCredit.reason && dataEditCredit.reason == "Chuyển đổi thẻ" && (user.role == 1) ?
                <div className="row item-popup">
                  <div className="col-3">Sửa tiền</div>
                  <div className="col-9">
                    <CurrencyInput
                      name="money_credit"
                      className="input-credit money-credit"
                      value={dataEditCredit.change_price}
                      groupSeparator={"."}
                      decimalSeparator={","}
                      onChange={changePrice}
                    />
                  </div>
                </div>
               : null}
              {!['Nhượng thẻ', 'Nâng cấp thẻ buổi', 'Nâng cấp thẻ triệt', 'Nâng cấp thẻ triệt premium', 'Nâng cấp thẻ triệt fullbody'].includes(dataEditCredit.reason) ?
                <div className="row item-popup">
                  <div className="col-3">
                    {dataEditCredit.reason && dataEditCredit.reason == "Chuyển đổi thẻ" ? "Số tiền chuyển đổi vào thẻ" : "Số tiền muốn cập nhật"}</div>
                  <div className="col-9">
                    <div className="money-credit">
                      {dataEditCredit.reason && dataEditCredit.reason == "Chuyển đổi thẻ" ?
                        <div className="price_change">{convert.convertCurrency(dataEditCredit.change_price)}</div>
                      : <CurrencyInput
                          name="money_credit"
                          className="input-credit money-credit"
                          value={dataEditCredit.change_price}
                          groupSeparator={"."}
                          decimalSeparator={","}
                          onChange={changePrice}
                        />
                      }
  
                    </div>
                  </div>
                </div>
              : null}
              {dataEditCredit.reason != "Nhượng thẻ" && dataEditCredit.money_wallet ?
                 <div className="row item-popup">
                  <div className="col-3">Số điểm tích luỹ</div>
                  <div className="col-9">
                    <div className="money-credit">
                      {dataEditCredit.reason && dataEditCredit.reason == "Chuyển đổi thẻ" ?
                        <div className="price_change">{convert.convertCurrency(dataEditCredit.money_wallet)} <span style={{color: 'red', fontSize: '12px'}}> - ( Note: Không được hoàn trả vào tài khoản tích luỹ )</span></div>
                      : <CurrencyInput
                          name="money_credit"
                          className="input-credit money-credit"
                          value={dataEditCredit.money_wallet}
                          groupSeparator={"."}
                          decimalSeparator={","}
                          onChange={changePrice}
                        />
                      }
  
                    </div>
                  </div>
               </div>
              : null}
              <div className="row item-popup">
                <div className="col-3">Ghi chú</div>
                <div className="col-9">
                  <textarea name="note-credit" onChange={changeNote} />
                </div>
              </div>
            </div>
          </div>
          {!loading ?
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={props.onHandelPopup}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className={`button-cent ${(activeButtonSave) ? "" : "disable-button"}`}
                  onClick={(activeButtonSave) ? 
                    setShowPopupConfirm : () => {}}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
            : null}

          {confirmPopup ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown">
                            <PopupConfirm
                                show={confirmPopup}
                                setShow={setShowPopupConfirm}
                                actionConfirm={ (['Nâng cấp thẻ buổi', 'Nâng cấp thẻ triệt', 'Nâng cấp thẻ triệt premium', 'Nâng cấp thẻ triệt fullbody'].includes(dataEditCredit.reason)) ? saveUpgradePackage :
                                addNewData}
                            ></PopupConfirm>
                        </div>
                        <div onClick={setShowPopupConfirm} className="overlay"></div>
                    </div>
                </div>
            : null}
        </div>
        <div onClick={props.onHandelPopup} className="overlay"></div>
      </div>
    </div>
  );
}
