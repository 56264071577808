import  { useState, useEffect } from 'react';
import StorageService from "../../utils/storage.service";
import { Link } from "react-router-dom";
import axiosService from "../../utils/axios.service";
import { notifications } from "../constant/Notify";
import * as convert from "../constant/Convert.helper"
let _ = require('lodash');

var LOCAL_STORAGE_CURRENTSTORE_KEY = "currentStore";
var _props = {};
var _state = {};
var _currentStore = undefined;

function storeItem(store) {
    let nameStoreConvert = convert.convertViToEn(store.name_store.toLowerCase())
    let nameSearchConvert = convert.convertViToEn(_state.searchStore.toLowerCase())
    if (!nameStoreConvert.includes(nameSearchConvert)) return ''
    return (
        <li key={store.id} className="media ng-scope">
            <div className="media-left">
                <div className="color-indicator blue"/>
            </div>
            <div className="media-body">
                <div className="row align-items-center">
                    <div className="col-8">
                        <div className="media-heading">
                            <span className="text-semibold">
                                {store.name_store}
                            </span>
                        </div>
                        <div className="text-muted">
                            {store.address}
                        </div>
                    </div>
                    <div className="col-4 text-center">
                        {_currentStore.id !== store.id ?
                            <button className="btn btn-xs btn-default btn-change-location" 
                                value={store.id}
                                name={store.name_store}
                                onClick={pickStore}
                                style={{"width": "100%"}}> Chọn </button>
                        : null}
                    </div>
                </div>
            </div>
        </li>
    );
}

function clickBooking(booking) {
    let url =  '/booking?action=view&booking_id=' + booking.id + "&cust_id=" + booking.customers.id
    window.location.replace(url)
}

function listCustomerBooking(store) {
    return (
        <li key={store.id} className="media ng-scope">
                <div className="media-left">
                    <div className="color-indicator blue"/>
                </div>
                <div className="media-body">
                    <div className="row align-items-center">
                        <div className="col-4">
                            <div className="customer-avatar">
                                <a href={store.customers.avata_url ?? 'https://placehold.co/105x'} target='_blank'>
                                    <img src={store.customers.avata_url ?? 'https://placehold.co/105x'} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="customer-avatar">
                                <a href={store.listImage[0].original ?? 'https://placehold.co/105x'} target='_blank'>
                                    <img src={store.listImage[0].original ?? 'https://placehold.co/105x'} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-12" style={{marginTop: '10px'}}>
                            <div className="media-heading" style={{marginBottom: '0px'}}>
                                <span className="text-semibold" style={{fontSize: "14px", fontWeight: "bold"}}>
                                {store.customers.full_name} {store.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                - {store.customers.mobile}
                                </span>
                            </div>
                            <div className="text-muted">
                                <p className="sale">Thời gian: { convert.convertDateTime(store.book_date)}</p>
                            </div>
                            <div className="text-muted">
                                <span className="cskh" style={{black: "black"}}><span style={{color: 'black'}}>Dịch vụ :</span> {store.service}</span>
                            </div>
                            <div className="media-heading" style={{fontSize: '#14px', textDecoration: "underline", fontWeight: '700'}} onClick={() => clickBooking(store)}>Xem lịch
                            </div>
                        </div>
                    </div>
                </div>
        </li>
    );
}

function storeHeaderBlock() {
    let stores = _props.stores
    return (
        <div className="dropdown-menu dropdown-content width-350" style={{display: "block"}}>
            <div className="dropdown-content-heading">
                Chi nhánh làm việc
                <span className="pull-right">
                    <Link to={{ pathname: "/stores" }}>
                        Chỉnh sửa
                    </Link>
                </span>
            </div>
            <div className="input-search">
                <div style={{"position": "relative"}}>
                    <input id="search-location-header-input" 
                            placeholder="Tìm tên chi nhánh"
                            value={_state.searchStore}
                            onChange={searchStoreHandle}
                            className="search-input form-control input-xs" type="text" />
                </div>
            </div>
            <ul className="media-list dropdown-content-body notification-list pt-10">
                {stores.map(store => storeItem(store))}
            </ul>
      </div>
    )
}

function pickStore(e) {
    let storeId = e.target.value
    StorageService.set(LOCAL_STORAGE_CURRENTSTORE_KEY, storeId)
    StorageService.set('currentNameStore', e.target.name)
    window.location.reload()
}

function searchStoreHandle(e) {
    let storeSearchString = e.target.value
    _state.setSearchStore(storeSearchString)
}

export default function StoreHeader(props) {
    const [pickUpStore, setPickUpStore] = useState(false);
    const [listCustomer, setListCustomer] = useState(false);
    const [searchStore, setSearchStore] = useState('');
    const [listBookingHanet, setListBookingHanet] = useState([])
    const currentStore = props.stores.filter((store) => {
        if (store.id === Number(StorageService.getItem(LOCAL_STORAGE_CURRENTSTORE_KEY))) return store
    })

    useEffect(async () => {
        if (process.env.REACT_APP_PRODUCTION == "production") {
            await defaultList(40000)
        }
    },[]);

    useEffect(() => {
        async function getDataHanet() {
            let data = await axiosService('api/hanet-ai/customers/' + localStorage.getItem("currentStore"), "GET")
            if (data.data.message == "success") {
                let newData = []
                let customerIdBooking = []
                let listImageHanet = data.data.data[1]
                for (let item of data.data.data[0]) {
                    let images = _.filter(listImageHanet, function(o) { return o.aliasID == item.customers.id; });
                    let newListImage = []
                    if (images.length > 0) {
                        images.map(x => {
                            newListImage.push({original: x.detected_image_url, thumbnail: x.detected_image_url })
                        })
                    }
                    customerIdBooking.push(item.customers.id)
                    let service = ''
                    let serviceFull = ''
                    let i = 1
                    for (let sv of item.booking_item[0].product_ids) {
                        if (i == 3) service += sv.product_name + ' ...'
                        if (i < 3) service += sv.product_name + ', '
                        
                        serviceFull += sv.product_name + ', '
                        i ++
                    }
                    item['service'] = service
                    item['serviceFull'] = serviceFull
                    item['listImage'] = newListImage
                    newData.push(item)
                }
                setListBookingHanet(newData)
            }
          }
          getDataHanet();
    },[]);

    const defaultList = async (time) => {
        const interval = setInterval(async () => {
            let data = await axiosService('api/hanet-ai/customers/' + localStorage.getItem("currentStore"), "GET")
            if (data.data.message == "success") {
                let newData = []
                let customerIdBooking = []
                let listImageHanet = data.data.data[1]
                for(let item of data.data.data[0]) {
                    let images = _.filter(listImageHanet, function(o) { return o.aliasID == item.customers.id; });
                    let newListImage = []
                    if (images.length > 0) {
                        images.map(x => {
                            newListImage.push({original: x.detected_image_url, thumbnail: x.detected_image_url })
                        })
                    }
                    customerIdBooking.push(item.customers.id)
                    let service = ''
                    let serviceFull = ''
                    let i = 1
                    for (let sv of item.booking_item[0].product_ids) {
                        if (i == 3) {
                            service += sv.product_name + ' ...'
                        }
                        if (i < 3) {
                            service += sv.product_name + ', '
                        }
                        serviceFull += sv.product_name + ', '
                        i ++
                    }
                    item['service'] = service
                    item['serviceFull'] = serviceFull
                    item['listImage'] = newListImage
                    newData.push(item)
                }
                setListBookingHanet(newData)
                
                let hanetIds = []
                let customerIds = []
                if (data.data.data[1].length > 0) {
                    for (let item of data.data.data[1]) {
                        if (item.check_notification == 1 && !customerIds.includes(item.aliasID) && customerIdBooking.includes(item.aliasID)) {
                            hanetIds.push(item.id)
                            customerIds.push(item.aliasID)
                            setTimeout( 
                                notifications({ type: 'customer', mess: "Khách hàng " +  item.personName + " đến làm dịch vụ" }),
                             1000);
                        }
                    }
                }
                if (customerIds.length > 0) {
                    await axiosService('api/hanet-ai/update/hanet', "POST", hanetIds)
                }
            }
        }, time);
    }
    
    if (currentStore[0] === undefined) return ''
    _props = props
    _state.searchStore = searchStore
    _state.setSearchStore = setSearchStore
    _currentStore = currentStore[0]
    return (
        <div className="menu-item choose-location">
            <div className="pre-booking hide-mobile">
                <span className="customer-bell"> 
                    <i className="fas fa-bell" style={{"fontSize": "20px", "marginTop": "5px"}} onClick={() => setListCustomer(!listCustomer)}></i>
                    <span className="count-customer">{listBookingHanet.length}</span>
                    {(listCustomer) ? 
                    <div className="dropdown-menu dropdown-content width-350" style={{display: "block", right: "unset", left: "-70px"}}>
                        <div className="dropdown-content-heading">Danh sách khách đến làm dịch vụ</div>
                        <ul className="media-list dropdown-content-body notification-list pt-10" style={{maxHeight: "60vh"}}>
                            {(listBookingHanet.length > 0) ? listBookingHanet.map(stores => listCustomerBooking(stores)) : ''}
                        </ul>
                    </div>
                 : null}
                </span>
            </div>
            <div className="parent-title" onClick={() => setPickUpStore(!pickUpStore)}>
                <img className="hide-mobile" src="/images/header/logo-store-header.jpeg" alt="logo" />
                <div className="working-locations">{currentStore[0].name_store}</div>
                <i className="fas fa-caret-down" style={{"fontSize": "10px", "marginLeft": "3px"}}></i>

            </div>
            {(pickUpStore) ? storeHeaderBlock() : null}
        </div>
    )
}